@media print {
  body {
    height: unset;
    min-height: unset;
    background: none;
  }

  .app-with-sidebar {
    display: block;
    height: unset;
  }

  .app-body {
    width: 100%;
    overflow: unset;
  }

  .sidebar {
    display: none;
    box-shadow: none;
  }

  .sidebar__icon.sidebar__icon--open {
    display: none;
  }

  h1.page-header__title {
    margin-left: 150px;
    text-wrap: nowrap;
  }

  .overflow-wrapper {
    overflow: unset;
  }

  .chair-reports-summary .accordion__content {
    display: block;
  }

  .no-print {
    display: none !important;
  }

  .print__logo {
    position: absolute;
    left: var(--op-space-small);
    margin: var(--op-space-small);
    width: 108px;
  }

  @media (max-width: 48rem) {
    nav[role=off-canvas] {
      display: none;
    }
  }

  .breadcrumbs {
    display: none !important;
  }

  th {
    font-size: var(--op-font-2x-small) !important;
  }

  .chart__tab {
    display: none;
  }
}
