.accordion {
  border: 1px solid var(---op-color-border);
  border-radius: var(--op-radius-large);
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
}

.accordion--with-border {
  border-bottom: 1px solid var(--op-color-border);
}

.accordion-group__header {
  opacity: .6;
  font-size: var(--op-font-small);
  font-weight: 500;
}

.accordion__header {
  background: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  padding: var(--op-space-large);
  cursor: pointer;
  font-size: var(--op-font-small);
  font-weight: var(--op-font-weight-normal);
  transition: var(--c12-transition-slow);

  &:hover {
    background: var(--op-color-neutral-plus-seven);
    color: var(--op-color-neutral-on-plus-seven);
  }

  .material-symbols-outlined {
    color: var(---op-color-neutral-on-plus-six);
    width: 24px;
    height: 24px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .status {
    span {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
}

.accordion__header--compact {
  padding: var(--op-space-small);
}

.accordion__content {
  padding: 0;
  display: none;

  .subtitle {
    padding: var(--op-space-large);
  }
}

// TODO add transitions here
.accordion__content--open {
  display: block;
}


// Custom Optics Accordion Component used for displaying Resrources
.accordion-resource {
  @extend %accordion-global;

  summary {
    padding-inline: var(--op-space-small);
    background-color: var(--op-color-neutral-plus-eight);

    &:hover {
      background-color: var(--op-color-neutral-plus-seven);
      color: var(--op-color-neutral-on-plus-seven);

      .accordion__marker {
        rotate: 0.25turn;
      }
    }
  }

  .accordion__label {
    color: var(--op-color-neutral-on-plus-max);
    font-size: var(--op-font-small);
    letter-spacing: var(--op-letter-spacing-label);
    line-height: var(--op-line-height-densest);
    font-weight: var(--op-font-weight-medium);
  }
}

.accordion--single-item {
  summary {
    display: flex;
  }
}

.curriculum-resource--devotion summary {
  background-color: var(--curriculum-resource-devotion-color);

  .accordion__label {
    color: var(--curriculum-resource-devotion-on-color);
  }
}

.curriculum-resource--business summary {
  background-color: var(--curriculum-resource-business-color);

  .accordion__label {
    color: var(--curriculum-resource-business-on-color);
  }
}

.curriculum-resource--ministry summary {
  background-color: var(--curriculum-resource-ministry-color);

  .accordion__label {
    color: var(--curriculum-resource-ministry-on-color);
  }
}

.curriculum-resource--business_ministry summary {
  background-color: var(--curriculum-resource-business_ministry-color);

  .accordion__label {
    color: var(--curriculum-resource-business_ministry-on-color);
  }
}
