.dashboard-grid {
  --c12-dashboard-grid-column-width: 30rem;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--c12-dashboard-grid-column-width), 1fr));
  gap: var(--op-space-x-large);
}

.dashboard-grid__item-group {
  display: flex;
  flex-direction: column;
  row-gap: var(--op-space-x-large);
  min-width: 0;
}

.dashboard-card {
  display: flex;
  flex-direction: column;
  row-gap: var(--op-space-x-large);
  align-items: start;
}

.dashboard-card__section {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: var(--op-space-small);

  &.dashboard-card__section--small {
    row-gap: var(--op-space-x-small);
  }

  &.dashboard-card__section--large {
    row-gap: var(--op-space-medium);
  }
}

.dashboard-card__icon {
  stroke: var(--op-color-primary-on-plus-three);
}

.dashboard-card__title {
  font-family: var(--op-font-family-alt);
  font-size: var(--op-font-2x-large);
  font-weight: var(--op-font-weight-bold);
}

.dashboard-card__subtitle {
  font-size: var(--op-font-small);
  font-weight: var(--op-font-weight-medium);
  text-transform: uppercase;
  line-height: var(--op-line-height-densest);
  letter-spacing: 0.1em;
  color: var(--op-color-neutral-on-plus-max);

  &.dashboard-card__subtitle--light {
    color: var(--op-color-neutral-on-plus-max-alt);
  }
}

.dashboard-card__resource {
  border-left: var(--op-border-width-large) solid var(--op-color-alerts-notice-base);
  padding: var(--op-space-x-small) var(--op-space-medium);
  display: flex;
  flex-direction: column;
  row-gap: var(--op-space-x-small); // Reduce this to var(--op-space-small) when .resource__title has truncation
  color: var(--op-color-neutral-on-plus-max);
}

.resource__kicker {
  font-size: var(--op-font-small);
  font-weight: var(--op-font-weight-medium);
  text-transform: uppercase;
  line-height: var(--op-line-height-densest);
  letter-spacing: 0.1em;
  color: var(--op-color-alerts-notice-base);
}

.resource__title {
  font-family: var(--op-font-family-alt);
  font-size: var(--op-font-medium);
  font-weight: var(--op-font-weight-bold);
  line-height: var(--op-line-height-denser);
}

.dashboard-card__snapshots {
  align-items: stretch;
}

.dashboard-card__snapshot {
  display: flex;
  justify-content: space-between;
}

.snapshot-insights {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-2x-small);
  margin-top: var(--op-space-medium);
}

.snapshot__leaders {
  flex-grow: 1;
  display: inline;
  background-image: linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  margin-bottom: 6px;
}

.snapshot__value {
  display: flex;
  align-items: center;
  gap: var(--op-space-2x-small);
  text-align: right;
  font-size: var(--op-font-x-small);
}

.snapshot-tag {
  color: var(--op-color-neutral-minus-max);
  border-radius: var(--op-radius-pill);
  padding: var(--op-space-2x-small) var(--op-space-x-small);
  line-height: var(--op-line-height-densest);

  &.snapshot-tag--good {
    background-color: var(--op-color-alerts-notice-plus-four);
    border: var(--op-border-width) solid var(--op-color-alerts-notice-plus-one);
  }

  &.snapshot-tag--caution {
    background-color: var(--op-color-alerts-warning-plus-four);
    border: var(--op-border-width) solid var(--op-color-alerts-warning-plus-one);
  }

  &.snapshot-tag--danger {
    background-color: var(--op-color-alerts-danger-plus-four);
    border: var(--op-border-width) solid var(--op-color-alerts-danger-plus-one);
  }
}

.snapshot-tag__trend {
  display: flex;
  align-items: center;
}

.dashboard-todo {
  display: flex;
  justify-content: space-between;
  gap: var(--op-space-x-small);
  font-size: var(--op-font-small);
  align-items: center;

  .form-control {
    height: 1lh;
  }
}
