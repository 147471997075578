.pagination-wrapper {
  padding: var(--op-space-2x-small) 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

// Quick hack: the template needs to be refactored to use optics buttons 
.pagination {
  justify-content: flex-end;
  span {
    @extend %btn-global, .btn--no-border;
  }
}
