// required for lazy loaded turbo frames. With out this, all of them load at once
.curriculum-container {
  display: block;
  min-height: 200px;
}

%curriculum-resource {
  --curriculum-resource-color: var(--curriculum-resource-devotion-on-color);
  display: flex;
  flex-direction: column;
  gap: var(--op-space-x-small);
  padding-inline-start: var(--op-space-small);
  padding-block: var(--op-space-2x-small);
  box-shadow: inset var(--op-border-width-large) 0 0 0 var(--curriculum-resource-color);


  .curriculum-resource__kind {
    display: flex;
    gap: var(--op-space-2x-small);
    justify-content: space-between;
    align-items: center;

    p {
      font-size: var(--op-font-x-small);
      letter-spacing: var(--op-letter-spacing-label);
      line-height: var(--op-line-height-densest);
      font-weight: var(--op-font-weight-medium);
      color: var(--curriculum-resource-color);
    }
  }

  .curriculum-resource__actions {
    display: flex;
    align-items: center;
    gap: var(--op-space-small);
    line-height: var(--op-line-height-densest);
  }

  .curriculum-resource__title {
    font-family: var(--op-font-family-alt);
    font-size: var(--op-font-medium);
    font-weight: var(--op-font-weight-bold);
    line-height: var(--op-line-height-denser);
    margin-bottom: 0;
  }

  .curriculum-resource__body {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-medium);
    transform-origin: top center;

    p {
      font-family: var(--op-font-family-alt);
      font-size: var(--op-font-small);
      color: var(--op-color-neutral-on-plus-max);
    }

  }
}

.curriculum-resource {
  @extend %curriculum-resource;

  &.curriculum-resource--devotion {
    --curriculum-resource-color: var(--curriculum-resource-devotion-on-color);
  }

  &.curriculum-resource--business {
    --curriculum-resource-color: var(--curriculum-resource-business-on-color);
  }

  &.curriculum-resource--ministry {
    --curriculum-resource-color: var(--curriculum-resource-ministry-on-color);
  }

  &.curriculum-resource--business_ministry {
    --curriculum-resource-color: var(--curriculum-resource-business_ministry-on-color);
  }
}

.resource__attachments {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);
  visibility: hidden;
  opacity: 0;
  transition: var(--c12-transition-slow);

  &.accordion__content--open {
    visibility: visible;
    opacity: 1;
  }
}

.file-attachment {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--op-space-small);
  padding: var(--op-space-x-small);

  @container (width < #{$breakpoint-x-small}) {
    flex-direction: column;
    align-items: flex-start;
    padding: var(--op-space-small) 0;
  }

  & > * {
    flex: 1;
  }

  .file-attachment__title,
  .file-attachment__title a {
    display: flex;
    flex-grow: 1;
    gap: var(--op-space-2x-small);
    font-size: var(--op-font-small);
    font-weight: var(--op-font-weight-medium);
    line-height: var(--op-line-height-dense);
    align-items: flex-start;

    .icon--medium {
      height: 1lh;
      width: 1lh;
    }

    &.file-attachment__title--button {
      @extend .btn, .btn--small, .btn--outline;
      white-space: normal;
      padding-top: var(--op-space-x-small);
      padding-bottom: var(--op-space-x-small);
      text-align: left;
    }
  }

  .file-attachment__actions {
    display: flex;
    justify-content: flex-end;
    gap: var(--op-space-small);
  }
}

.quarter-line {
  font-size: var(--op-font-medium);
  display: inline-flex;
  justify-content: flex-end;
  gap: var(--op-space-2x-small);
  transform: translateX(-70%);
  width: 100%;
  position: relative;
  padding-bottom: var(--op-space-2x-small);

  &:after {
    content: "";
    background:
      linear-gradient(to right, var(--sidebar-border-color) 0%, var(--op-color-secondary-base) 100%);
    position: absolute;
    height: var(--op-border-width-large);
    width: 300%;
    bottom: 0;
    z-index: -1;
  }

  .quarter-line__quarter {
    font-weight: var(--op-font-weight-bold);
    color: var(--op-color-neutral-on-plus-eight);
  }

  .quarter-line__year {
    font-weight: var(--op-font-weight-normaal);
    color: var(--op-color-neutral-on-plus-eight-alt);
  }
}
