:root {
  --op-input-inner-focus: inset 0 0 0 var(--op-border-width-large);
  --op-input-outer-focus: 0 0 0 var(--op-border-width-large);
}

%form-control-input-global {
  border-radius: 0;
}

.simple_form {

  &.inline-form {
    width: auto;
    margin: 0;
    display: inline;
  }
}

// the date picker is readonly. This ensures that it works
.form-control[readonly] {
  display: block;
  width: 100%;
  padding: var(--op-space-small);
  font-size: var(--op-font-small);
  line-height: 1.5;
  color: var(--op-color-neutral-on-plus-max);
  background-color: var(--op-color-neutral-plus-max);
  border: var(--op-border-width) solid var(--op-color-neutral-plus-two);
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: var(--op-radius-large);
  box-shadow: none;
  transition: var(--c12-transition-fast);
  user-select: all;
  pointer-events: all;
}

input[hidden],
.form-group.hidden {
  display: none;
}

.half-width-input {
  width: 50%;

  .gap-md & {
    width: calc(50% - (var(--op-space-small) / 2))
  }
}

// simple_form styles
.form-error-summary {
  background-color: var(--op-color-alerts-danger-plus-eight);
  color: var(--op-color-alerts-danger-on-plus-eight);
  border-radius: var(--op-radius-large);
  border: 1px solid var(--op-color-alerts-danger-plus-six);
  margin-bottom: var(--op-space-large);
  padding: var(--op-space-large);

  h2 {
    font-size: var(--op-font-medium);
  }

  ul {
    margin-bottom: 0;
  }
}

.form-control--error {

  input,
  .StripeElement {
    box-shadow: inset 0 0 0 1px var(--op-color-alerts-danger-minus-three);
  }
}

// Stripe Styles
.StripeElement {
  display: block;
  margin: 0 0 var(--op-space-small) 0;
  width: 100%;
  padding: var(--op-space-small) var(--op-space-medium);
  font-size: var(--op-font-small);
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: var(--op-radius-sm);
  background: var(---op-color-primary-plus-max);

  select {
    border: none;
    width: 100%;
  }
}

.StripeElement:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

fieldset.attachable {
  transform: translateY(0);
  background-color: var(--op-color-background);
  color: var(--op-color-on-background);
  margin-top: var(--op-space-large);
  box-shadow: var(--c12-card-shadow);
  border-radius: var(--op-radius-small);
  appearance: none;
  border: 0;
  min-width: 0;

  legend {
    padding: 0 var(--op-space-small);
    font-size: var(--op-font-small);
    background-color: var(--op-color-primary-base);
    color: var(--op-color-primary-on-base);
  }

  // delete attachable button
  & > button[type="button"].btn--danger {
    position: absolute;
    top: 0;
    right: var(--op-space-small);
  }
}

.form-group.radio_buttons {
  display: block;
}

.inline-delete-button {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: var(--op-space-small);
}

.form-group--error + .inline-delete-button {
  padding-bottom: var(--op-space-3x-large);
}
