:root {
  --c12-tribute-container-min-width: calc(6.4 * var(--op-size-unit)); // 64px
}


.tribute-container {
  z-index: var(--op-z-index-dropdown);
  @extend %menu-global;
  min-width: var(--c12-tribute-container-min-width);
  contain: paint;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  li {

    display: unset;
    // Above sets the difference of BradenRich and Braden Rich
    // This is due to flex being set on menu-item, which is stripping the block layout that was being used.

    @extend .menu-item;
    padding: var(--op-space-x-small);

    &:hover {
      cursor: pointer;
      background: var(--op-color-primary-minus-three)
    }

    &.highlight {
      background: var(--op-color-primary-base);
      color: var(--op-color-primary-on-base);
    }
  }
}

.no-match-prompt {
  @extend .menu-item;
}

.user-mention {
  padding: var(--op-space-2x-small)!important;
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-four);
  background-color: var(--op-color-primary-plus-five);
  color: var(--op-color-primary-on-plus-five);
  border-radius: var(--op-radius-large);

  &+.attachment__caption {
    display: none!important;
  }
}
