%card-global {
  --card-nested-background: hsl(217, 15%, 97%);
  --c12-card-shadow-inset-color: var(--op-color-border);
  position: relative;
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  box-shadow: var(--c12-card-shadow);
  border-radius: 0;
  font-size: var(--op-font-medium);
  line-height: var(--op-line-height-base);
  overflow: hidden;

  // Modifiers
  &.card--nested {
    background-color: var(--op-color-neutral-plus-max);
    color: var(--op-color-neutral-on-plus-eight);
    box-shadow: var(--op-border-all) var(--op-color-border);
    padding: var(--op-space-medium);
  }

  &.card--themed {
    background-color: var(--op-color-primary-minus-five);
    color: var(--op-color-primary-on-minus-five);
    border: 1px solid var(--op-color-primary-minus-six);
  }

  .card--filled {
    background-color: var(--op-color-neutral-plus-eight);
    color: var(--op-color-neutral-on-plus-eight);
  }

  &.card--with-overflow {
    overflow: visible;
    contain: none;
  }

  // Card Elements
  .card__header,
  .card__body,
  .card__footer {
    padding: var(--op-space-large);

    &:not(:first-of-type) {
      border-top: var(--op-border-width) solid var(--color-outline);
    }
  }
}

.card {
  @extend %card-global;
}

.card__title {
  font-family: var(--op-font-family-alt);
  font-size: var(--op-font-large);
  font-weight: var(--op-font-weight-bold);
  a {
      font-weight: var(--op-font-weight-bold);
    }
}

.card-resource {
  @extend %card-global;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: var(--c12-transition-slow);
  overflow: visible;
  container-type: inline-size;
  container-name: card;

  .card__header {
    display: flex;
    flex-direction: column;
    gap: var(--op-space-medium);
    justify-content: flex-end;
    padding: var(--op-space-x-large) var(--op-space-x-large) 0;
  }

  .card__body {
    display: grid;
    grid-auto-rows: auto;
    padding: var(--op-space-x-large);
    gap: var(--op-space-medium);
    align-items: flex-start;
  }

  .card__footer {
    padding: 0 var(--op-space-x-large) var(--op-space-x-large);
  }


  .expandable-grid &:not(.card--expanded):hover {
    --c12-card-shadow-inset-color: var(--op-color-primary-plus-two);
    box-shadow: 0px 0px 0px 1px var(--c12-card-shadow-inset-color) inset, var(--c12-card-shadow);
  }
}

.card__header-actions {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  justify-content: center;
  gap: var(--op-space-medium);

.card__header-actions-end {
  display: grid;
  grid-auto-flow: column;
  gap: var(--op-space-x-small);
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  visibility: hidden;
  opacity: 0;
  transition: var(--c12-transition-fast);

  .card-resource:hover & {
    visibility: visible;
    opacity: 1;
  }

  @media (width < #{$breakpoint-medium}) {
    visibility: visible;
    opacity: 1;
  }
}
}

.card__header-icon {
  display: flex;
  justify-self: center;
  color: var(--op-color-primary-on-plus-max-alt);
}

.card__header-end {
  display: flex;
  gap: var(--op-space-x-small);
  justify-content: space-between;
  flex-wrap: wrap;
}


.card-padded {
  @extend %card-global;
  padding: var(--op-space-large);
}


.card-discussion {
  @extend %card-global;
  width: 100%;
  max-width: var(--op-breakpoint-small);
  margin-inline: auto;
  display: grid;
  grid-template-rows: auto;
  padding: var(--op-space-x-large);
  gap: var(--op-space-medium);
  container-type: normal;
  overflow: visible;
  contain: none;

  @media (width <=#{$breakpoint-x-small}) {
    padding: var(--op-space-medium);

  }
}

// TODO: find/replace the following as appropriate

.card--filled-background {
  background-color: var(--op-color-neutral-plus-eight);
  color: var(--op-color-neutral-on-plus-eight);
}

.card-avatar {
  background-color: var(--op-color-primary-plus-four);
  color: var(--op-color-primary-on-plus-four);
  width: 2.75em;
  height: 2.75em;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--op-space-large);
}

.dashboard-card {
  display: flex;
  flex-direction: column;
  row-gap: var(--op-space-x-large);
  align-items: start;
}

.dashboard-card__section {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: var(--op-space-small);

  &.dashboard-card__section--small {
    row-gap: var(--op-space-x-small);
  }

  &.dashboard-card__section--large {
    row-gap: var(--op-space-medium);
  }
}
.dashboard-card__icon {
  stroke: var(--op-color-primary-on-plus-three);
}

.dashboard-card__title {
  font-family: var(--op-font-family-alt);
  font-size: var(--op-font-2x-large);
  font-weight: var(--op-font-weight-bold);
}

.dashboard-card__subtitle {
  font-size: var(--op-font-small);
  font-weight: var(--op-font-weight-medium);
  text-transform: uppercase;
  line-height: var(--op-line-height-densest);
  letter-spacing: 0.1em;
  color: var(--op-color-neutral-on-plus-max);

  &.dashboard-card__subtitle--light {
    color: var(--op-color-neutral-on-plus-max-alt);
  }
}

.card-snapshot {
  min-width: 330px;
}
