:root {

  --fixed-cell-wrapper-height: calc(15 * var(--op-size-unit)); // 44px
  --fixed-cell-height: calc(11 * var(--op-size-unit)); // 44px
  --fixed-table-width-sm: calc(34 * var(--op-size-unit)); // 136px
  --fixed-table-width: calc(35 * var(--op-size-unit)); // 140px
  --fixed-table-width-lg: calc(62 * var(--op-size-unit)); // 248px

  // Panel
  --panel-width: 40%;
  --panel-transition-speed: 400ms;

  // Modals
  --_op-modal-width: calc(141 * var(--op-size-unit)); // 564px
  --modal-transition-speed: 300ms;

  --gap: var(--op-space-small);

  --column-width: calc(300 * var(--op-size-unit)); // 1200px

  --avatar-size-default: calc(10 * var(--op-size-unit)); // 40px

  --mobile-top-nav-height: calc(12 * var(--op-size-unit)); // 48px
  --android-tabbar-height: calc(17.5 * var(--op-size-unit)); // 70px
}
