:root {
  --c12-comment-input-min-height: 10rem;
}

.discussion__main {
  display: grid;
  gap: var(--op-space-large);
}

.discussion__body {
  display: grid;
  gap: var(--op-space-medium);
}

.discussion__meta {
  display: grid;
  grid-template-columns: 1fr auto; // One flexible column, one auto-sized
  grid-auto-flow: column;
  align-items: center; // Vertically align items in the center
  gap: var(--op-space-small);
}

.discussion__label {
  justify-self: start; // Align label to the start of its column
}

.discussion__header {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr auto auto;
  align-items: center;
  gap: var(--op-space-small);

  @media (width <=#{$breakpoint-x-small}) {
    grid-template: auto / auto;
    gap: var(--op-space-medium);
  }
}

.discussion__heading {
  display: grid;
}

.discussion__title,
.discussion__title a {
  font-family: var(--op-font-family-alt);
  font-weight: var(--op-font-weight-bold);
  font-size: var(--op-font-large);
  line-height: var(--op-line-height-dense);
  text-decoration: none;
  color: var(--op-color-neutral-on-plus-max);

  &.discussion__title--response {
    font-size: var(--op-font-medium);
  }
}

.discussion__subtitle {
  display: flex;
  gap: var(--op-space-x-small);
  align-items: center;
  font-size: var(--op-font-x-small);
  line-height: var(--op-line-height-dense);
  color: var(--op-color-on-background-alt);
}

.discussion__subtitle--response {
  font-size: var(--op-font-medium);
  font-weight: 500;
  color: var(--op-color-neutral-on-plus-eight-alt); //sits on card--nested
}


.discussion__date {
  font-size: var(--op-font-x-small);
  color: var(--op-color-on-background-alt);

  @media (width <=#{$breakpoint-x-small}) {
    grid-row: 2;
  }
}

.discussion__preview {
  font-size: var(--op-font-small);
  font-family: var(--op-font-family-alt);
}

.discussion__footer {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  @media (max-width: #{$container-breakpoint-card-width}) {
    grid-template-columns: 1fr;
    grid-auto-flow: initial;
    gap: var(--op-space-medium);
    text-align: center;
  }
}

.discussion__actions {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  gap: var(--op-space-small);
}

.discussion__response-count {
  color: var(--op-color-on-background-alt); // TODO: use semantic
  display: flex;
  gap: var(--op-space-2x-small);
  align-items: center;
  font-size: var(--op-font-x-small);

  .material-symbols-outlined {
    vertical-align: -25%;
    margin-right: var(--op-space-2x-small);
  }
}

.discussion__reply {
  margin-bottom: var(--op-space-large);
  padding-left: var(--op-space-small);
  border-left: var(--op-border-width-large) solid var(--op-color-neutral-plus-six);
}

.discussion__comment-form {
  --__op-form-control-height: min-content;
  @extend %form-control-input-global, %form-control-global;
  border-radius: 0;
  padding: var(--op-space-small);


  &:focus-within,
  &:focus-visible:not([readonly]) {
    @extend %form-control-input-global;
    border-radius: 0;
    padding: var(--op-space-small);
    background-color: var(--op-color-primary-plus-seven);
    box-shadow: var(--op-input-focus-primary);
    color: var(--op-color-primary-on-plus-seven);
  }s

  // Hover State
  &:hover:not(:disabled, [readonly]) {
    box-shadow: var(--op-border-all) var(--op-color-primary-plus-three);
  }

  form {
    position: relative;
    display: grid;
    grid-auto-flow: row;
    align-items: flex-end;
    gap: var(--op-space-small);
  }

    .form-control:not([type=radio], [type=checkbox]) {
      border: none;
      border-radius: 0;
      appearance: none;
      background-color: transparent;
      box-shadow: none;
      padding: 0;

      &:hover:not(:disabled, [readonly]) {
        box-shadow: none;
      }
  }

  .form-control--custom {
    --_op-form-control-height-large: var(--c12-comment-input-min-height);
    height: min-content;

    // Focus State
    &:focus-visible:not([readonly]) {
      background-color: transparent;
      box-shadow: none;
      color: var(--op-color-primary-on-plus-seven);
    }
  }

  input,
  textarea {
    @extend %form-control-input-global;
  }

  &:hover {
    box-shadow: var(--op-border-all) var(--op-color-primary-plus-three);
  }

  .btn-primary {
    justify-self: flex-end;
  }
}

// Misc

.trix-content {
  font-size: var(--op-font-small);
}
