.page-header {
  padding-top: var(--op-space-medium);
  padding-bottom: var(--op-space-medium);
  width: 100%;
  transition: var(--c12-transition-fast);
  @media (width <=$breakpoint-small) {
    padding-top: var(--op-space-small);
    padding-bottom: var(--op-space-small);
    padding-right: var(--op-space-x-small);
  }
}

.page-header__main {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: var(--op-space-large);

  @media (width <= $breakpoint-small) {
    grid-template-columns: 1fr;
    gap: var(--op-space-medium)
  }
}

.page-header__title {
  display: grid;
  justify-content: flex-start;
  gap: var(--op-space-x-small);
  color: var(--op-color-primary-minus-six);
  font-size: var(--op-font-medium);
  margin: 0;
  line-height: var(--op-line-height-base);

  @media (width <= $breakpoint-small) {
    font-size: var(--op-font-medium);
  }
}

.page-header__actions {
  display: flex;
  gap: var(--op-space-medium);
  align-items: center;
  justify-content: flex-end;
  @media (width <=$breakpoint-small) {
    flex-direction: column;
    align-items: stretch;
  }
}

.search__field {
  margin-bottom: var(--op-space-medium);
}

.search__field input[type="text"] {
  width: 100%;
  padding: var(--op-space-x-small);
}

.section__header {
  color: var(--op-color-on-background);
  font-size: var(--op-font-small);
}
