.styleguide-section__title {
  margin-bottom: var(--op-space-large);
  padding-bottom: var(--op-space-x-small);

  border-bottom: var(--op-border-width-large) solid var(--op-color-border);

  &::before {
    margin-right: var(--op-space-x-small);

    content: '#';

    color: var(--op-color-neutral-on-plus-max);
  }
}

.styleguide-button {
  width: 100%;
  margin: .5rem 0;
}

.spacing-example {
  position: relative;

  display: block;

  height: 2rem;
  margin-bottom: var(--op-space-large);

  background: var(--op-color-primary-minus-three);
}
