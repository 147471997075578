.identity {
  background-color: var(--sidebar-identity-color);
  color: var(--sidebar-identity-on-color);
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: var(--op-space-small);
  box-shadow: inset var(--op-border-all) var(--sidebar-identity-border-color);
  border-radius: var(--op-radius-small);
  width: 100%;
  gap: var(--op-space-small);
  transition: var(--c12-transition-fast);

  &:hover {
    background-color: var(--sidebar-identity-hover-color);
    color: var(--sidebar-identity-hover-on-color);
    box-shadow: inset var(--op-border-all) var(--sidebar-identity-hover-border-color);
  }

  &:active {
    background-color: var(--sidebar-identity-hover-color);
    color: var(--sidebar-identity-hover-on-color);
    box-shadow: inset var(--op-border-all) var(--sidebar-identity-hover-border-color);
  }

  // Focus State
  &:focus,
  &:focus-within,
  &:focus-visible {
    background: var(--op-color-tertiary-minus-six);
    color: var(--op-color-tertiary-on-minus-six);
    box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-two);
  }
  
  @media (width <= $breakpoint-x-small) {
    padding: var(--op-space-small);
  }
  
  @media (width >= $breakpoint-small) and (width <= $breakpoint-medium) {
    gap: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: var(--op-radius-circle);
    
    &:hover {
      box-shadow: var(--op-border-all) var(--op-color-neutral-plus-two);
    }
    
    &:active {
      background-color: transparent;
      box-shadow: var(--op-border-all) var(--op-color-tertiary-plus-two);
    }
  }
}

.identity__wrapper {
  position: relative;
  z-index: 1;
}

.identity__info {
  display: flex;
  flex-direction: column;
  font-size: var(--op-font-x-small);
  line-height: var(--op-line-height-denser);
  gap: var(--op-space-2x-small);
  
  // Hide the text between medium and small breakpoints
  @media (width >=$breakpoint-small) and (width <=$breakpoint-medium) {
    .sidebar & {
      font-size: 0;
    }
  }
}

.identity__avatar {
  background-color: var(--op-color-secondary-plus-four);
  color: var(--op-color-secondary-on-plus-four);
  width: var(--avatar-size-default);
  height: var(--avatar-size-default);
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.identity__avatar--large {
  width: 6.5em;
  height: 6.5em;
}

.identity__title {
  font-size: 95%;
}

.identity__icon {
  font-size: var(--op-font-2x-large);
}

.identity__tray {
  background-color: var(--op-color-tertiary-minus-seven);
  color: var(--op-color-tertiary-on-minus-seven);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-one);
  border-radius: var(--op-radius-small);
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: var(--op-space-small);
  font-size: var(--op-font-small);
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(0);
  z-index: -1;
  transition: all 150ms;
}

// TODO add transitions here
.identity__tray--open {
  opacity: 1;
  z-index: 1;
  height: auto;
  overflow: visible;
  transform: translateY(-100%) translateY(-10px);

  @media (width <=$breakpoint-medium) {
    position: fixed;
    max-width: calc(var(--_op-sidebar-drawer-width) - 2 * var(--_op-sidebar-spacing));
    .mobile-navigation-content & {
      position: absolute;
    }
  }
  
}

.identity__tray-label {
  color: var(--op-color-tertiary-on-minus-seven);
  margin: var(--op-space-small);

  .icon {
    font-size: var(--op-font-x-large);
    vertical-align: sub;
    margin-bottom: calc(-1 * var(--op-space-2x-small));
  }
}

.identity__switch {
  display: block;
  padding: var(--op-space-x-small) var(--op-space-small);
  color: inherit;

  &:hover {
    background: var(--op-color-tertiary-minus-six);
    color: var(--op-color-tertiary-on-minus-six);
  }
}

.identity__switch--disabled {
  background: var(--op-color-tertiary-minus-eight);
  color: var(--op-color-tertiary-on-minus-eight);
}

.identity__item {
  font-weight: var(--op-font-weight-normal);
  padding: var(--op-space-x-small) var(--op-space-small);
  border-radius: var(--op-radius-small);
  color: inherit;

  &:hover {
    background: var(--op-color-tertiary-minus-six);
    color: var(--op-color-tertiary-on-minus-six);
  }

  &:active {
    background: var(--op-color-tertiary-minus-four);
    color: var(--op-color-tertiary-on-minus-four);
  }
}

.sign-out {
  color: var(--op-color-alerts-danger-plus-three);
}

.identity__separator {
  width: 100%;
  height: var(--op-border-width);
  background-color: var(--op-color-primary-plus-two);
}
