.wheel {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.wheel__area--warning {
  fill: hsl(60, 2%, 80%);
}

.wheel__area--danger {
  fill: hsl(30, 2%, 70%);
}

.wheel__ring {
  stroke: #726f6c;
  stroke-width: 1pt;
  fill: transparent;
}

.wheel__ring--important {
  stroke-width: 2pt;
}

.wheel__ring--master {
  stroke: #3d3935;
  stroke-width: 2pt;
}

.wheel__division {
  stroke: #979593;
  stroke-width: 1pt;
  fill: transparent;
}

.wheel__division--intermediate {
  stroke: #aba9a7;
  stroke-width: 1pt;
  stroke-dasharray: 2 2;
  fill: transparent;
}

.wheel__roundel {
  stroke: #8a8885;
  stroke-width: 2pt;
  fill: #a7a5a3;
}

.wheel__diagram {
  stroke: var(--op-color-primary-minus-three);
  stroke-width: 4pt;
  stroke-linecap: square;
  fill: var(--op-color-primary-minus-three);
  fill-opacity: 30%;
}

.wheel__label {
  font-size: 12pt;
  fill: hsl(0, 0%, 0%);
  font-family: sans-serif;
  font-weight: 400;
}

.wheel__legend {
  fill: #979593;
  font-family: serif;
  font-size: 12pt;
}
