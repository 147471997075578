.flash {
  position: fixed;
  top: var(--op-space-small);
  right: var(--op-space-small);
  padding: var(--op-space-small) var(--op-space-small);
  margin-bottom: var(--op-space-small);
  border: var(--op-border-width) solid transparent;
  border-radius: var(--op-radius-large);
  box-shadow: var(--op-shadow-x-large);
  max-width: 375px;
  font-size: var(--op-font-small);
  line-height: var(--op-font-x-large);
  z-index: var(--op-z-index-alert-group);

  animation: animateMessage 7s normal forwards;

  @media(max-width: #{$breakpoint-x-small}) {
    max-width: calc(100% - var(--op-space-small) - var(--op-space-small));
    min-width: calc(100% - var(--op-space-small) - var(--op-space-small));
  }
}

@keyframes animateMessage {
  0% {
    right: -100%;
  }
  1% {
    right: -100%;
  }
  10% {
    right: 1rem;
  }
  70% {
    right: 1rem;
  }
  100% {
    right: -100%;
  }
}

.flash--notice {
  background-color: var(--op-color-alerts-notice-plus-eight);
  box-shadow: var(--op-border-all) var(--op-color-alerts-notice-base);
  color: var(--op-color-alerts-notice-on-plus-eight);
}

.flash--info {
  background-color: var(--op-color-alerts-info-plus-eight);
  box-shadow: var(--op-border-all) var(--op-color-alerts-info-base);
  color: var(--op-color-alerts-info-on-plus-eight);
}

.flash--alert {
  background-color: var(--op-color-alerts-danger-plus-eight);
  box-shadow: var(--op-border-all) var(--op-color-alerts-danger-base);
  color: var(--op-color-alerts-danger-on-plus-eight);
}

.flash--warning {
  color: var(--op-color-alerts-warning-on-plus-eight);
  background-color: var(--op-color-alerts-warning-plus-eight);
  box-shadow: var(--op-border-all) var(--op-color-alerts-warning-base);
}
