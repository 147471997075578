@mixin color-scale($name, $h, $s, $l) {
  --op-color-#{$name}-h: #{$h};
  --op-color-#{$name}-s: #{$s};
  --op-color-#{$name}-l: #{$l};

  --op-color-#{$name}-original: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) var(--op-color-#{$name}-l));

  // Main Scale
  --op-color-#{$name}-plus-max: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 100%);
  --op-color-#{$name}-plus-eight: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 98%);
  --op-color-#{$name}-plus-seven: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 96%);
  --op-color-#{$name}-plus-six: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 94%);
  --op-color-#{$name}-plus-five: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 90%);
  --op-color-#{$name}-plus-four: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 84%);
  --op-color-#{$name}-plus-three: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 70%);
  --op-color-#{$name}-plus-two: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 64%);
  --op-color-#{$name}-plus-one: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 45%);
  --op-color-#{$name}-base: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 40%);
  --op-color-#{$name}-minus-one: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 36%);
  --op-color-#{$name}-minus-two: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 32%);
  --op-color-#{$name}-minus-three: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 28%);
  --op-color-#{$name}-minus-four: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 24%);
  --op-color-#{$name}-minus-five: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 20%);
  --op-color-#{$name}-minus-six: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 16%);
  --op-color-#{$name}-minus-seven: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 12%);
  --op-color-#{$name}-minus-eight: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 8%);
  --op-color-#{$name}-minus-max: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 0%);

  // On Scale
  --op-color-#{$name}-on-plus-max: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 0%);
  --op-color-#{$name}-on-plus-max-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 20%);
  --op-color-#{$name}-on-plus-eight: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 4%);
  --op-color-#{$name}-on-plus-eight-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 24%);
  --op-color-#{$name}-on-plus-seven: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 8%);
  --op-color-#{$name}-on-plus-seven-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 28%);
  --op-color-#{$name}-on-plus-six: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 16%);
  --op-color-#{$name}-on-plus-six-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 26%);
  --op-color-#{$name}-on-plus-five: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 20%);
  --op-color-#{$name}-on-plus-five-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 40%);
  --op-color-#{$name}-on-plus-four: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 24%);
  --op-color-#{$name}-on-plus-four-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 4%);
  --op-color-#{$name}-on-plus-three: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 20%);
  --op-color-#{$name}-on-plus-three-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 10%);
  --op-color-#{$name}-on-plus-two: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 16%);
  --op-color-#{$name}-on-plus-two-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 6%);
  --op-color-#{$name}-on-plus-one: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 100%);
  --op-color-#{$name}-on-plus-one-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 95%);
  --op-color-#{$name}-on-base: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 100%);
  --op-color-#{$name}-on-base-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 88%);
  --op-color-#{$name}-on-minus-one: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 94%);
  --op-color-#{$name}-on-minus-one-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 82%);
  --op-color-#{$name}-on-minus-two: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 90%);
  --op-color-#{$name}-on-minus-two-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 78%);
  --op-color-#{$name}-on-minus-three: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 86%);
  --op-color-#{$name}-on-minus-three-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 74%);
  --op-color-#{$name}-on-minus-four: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 84%);
  --op-color-#{$name}-on-minus-four-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 72%);
  --op-color-#{$name}-on-minus-five: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 88%);
  --op-color-#{$name}-on-minus-five-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 78%);
  --op-color-#{$name}-on-minus-six: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 98%);
  --op-color-#{$name}-on-minus-six-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 94%);
  --op-color-#{$name}-on-minus-seven: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 90%);
  --op-color-#{$name}-on-minus-seven-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 84%);
  --op-color-#{$name}-on-minus-eight: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 98%);
  --op-color-#{$name}-on-minus-eight-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 86%);
  --op-color-#{$name}-on-minus-max: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 100%);
  --op-color-#{$name}-on-minus-max-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 88%);
}

@mixin dark-color-scale($name, $h, $s, $l) {
  --op-color-#{$name}-h: #{$h};
  --op-color-#{$name}-s: #{$s};
  --op-color-#{$name}-l: #{$l};

  --op-color-#{$name}-original: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) var(--op-color-#{$name}-l));

  // Main Scale
  --op-color-#{$name}-plus-max: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 12%);
  --op-color-#{$name}-plus-eight: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 14%);
  --op-color-#{$name}-plus-seven: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 16%);
  --op-color-#{$name}-plus-six: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 20%);
  --op-color-#{$name}-plus-five: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 24%);
  --op-color-#{$name}-plus-four: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 26%);
  --op-color-#{$name}-plus-three: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 29%);
  --op-color-#{$name}-plus-two: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 32%);
  --op-color-#{$name}-plus-one: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 35%);
  --op-color-#{$name}-base: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 38%);
  --op-color-#{$name}-minus-one: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 40%);
  --op-color-#{$name}-minus-two: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 45%);
  --op-color-#{$name}-minus-three: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 48%);
  --op-color-#{$name}-minus-four: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 52%);
  --op-color-#{$name}-minus-five: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 64%);
  --op-color-#{$name}-minus-six: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 72%);
  --op-color-#{$name}-minus-seven: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 80%);
  --op-color-#{$name}-minus-eight: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 88%);
  --op-color-#{$name}-minus-max: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 100%);

  // On Scale
  --op-color-#{$name}-on-plus-max: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 100%);
  --op-color-#{$name}-on-plus-max-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 78%);
  --op-color-#{$name}-on-plus-eight: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 88%);
  --op-color-#{$name}-on-plus-eight-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 70%);
  --op-color-#{$name}-on-plus-seven: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 80%);
  --op-color-#{$name}-on-plus-seven-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 64%);
  --op-color-#{$name}-on-plus-six: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 72%);
  --op-color-#{$name}-on-plus-six-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 96%);
  --op-color-#{$name}-on-plus-five: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 72%);
  --op-color-#{$name}-on-plus-five-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 86%);
  --op-color-#{$name}-on-plus-four: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 80%);
  --op-color-#{$name}-on-plus-four-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 92%);
  --op-color-#{$name}-on-plus-three: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 78%);
  --op-color-#{$name}-on-plus-three-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 98%);
  --op-color-#{$name}-on-plus-two: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 80%);
  --op-color-#{$name}-on-plus-two-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 92%);
  --op-color-#{$name}-on-plus-one: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 80%);
  --op-color-#{$name}-on-plus-one-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 98%);
  --op-color-#{$name}-on-base: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 100%);
  --op-color-#{$name}-on-base-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 84%);
  --op-color-#{$name}-on-minus-one: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 98%);
  --op-color-#{$name}-on-minus-one-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 90%);
  --op-color-#{$name}-on-minus-two: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 98%);
  --op-color-#{$name}-on-minus-two-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 92%);
  --op-color-#{$name}-on-minus-three: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 98%);
  --op-color-#{$name}-on-minus-three-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 96%);
  --op-color-#{$name}-on-minus-four: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 2%);
  --op-color-#{$name}-on-minus-four-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 2%);
  --op-color-#{$name}-on-minus-five: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 2%);
  --op-color-#{$name}-on-minus-five-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 20%);
  --op-color-#{$name}-on-minus-six: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 8%);
  --op-color-#{$name}-on-minus-six-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 26%);
  --op-color-#{$name}-on-minus-seven: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 8%);
  --op-color-#{$name}-on-minus-seven-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 34%);
  --op-color-#{$name}-on-minus-eight: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 4%);
  --op-color-#{$name}-on-minus-eight-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 38%);
  --op-color-#{$name}-on-minus-max: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 0%);
  --op-color-#{$name}-on-minus-max-alt: hsl(var(--op-color-#{$name}-h) var(--op-color-#{$name}-s) 38%);
}
