%container {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--op-space-large);
  padding-right: var(--op-space-large);
  container-type: inline-size;
  container-name: container;

  @media (width < $breakpoint-small) {
    padding-left: var(--op-space-x-small);
    padding-right: var(--op-space-x-small);
  }

  @media (width <= $breakpoint-small) {
    padding-left: 0;
    padding-right: 0;
  }

  @media print {
    padding-left: 0;
    padding-right: 0;
  }
}

.container {
  @extend %container;
  max-width: var(--op-breakpoint-large);
}

.container--max-width-medium {
  max-width: var(--op-breakpoint-medium);
}

.container--max-width-small {
  max-width: var(--op-breakpoint-small);
}

.container--sm {
  padding: 0 var(--op-space-small);
}

.container--xs {
  max-width: #{$breakpoint-x-small};
  margin: 0 auto;
  padding: 0 var(--op-space-large);
}

.container--report {
  width: #{$breakpoint-medium};
  margin: 0 auto;
  padding: var(--op-space-4x-large);
}

.container--page-header {
  @extend .container;
  max-width: none;
  padding-left: var(--op-space-large);
  padding-right: var(--op-space-large);
  display: grid;
  grid-auto-flow: row;
  gap: var(--op-space-2x-small);

  @media (width <= $breakpoint-small) {
    padding-left: 0;
    padding-right: 0;
  }
}

.container--full-screen {
  @extend .container;
  max-width: none;
  padding-left: var(--op-space-large);
  padding-right: var(--op-space-large);
}

.container-cards-list {
  display: grid;
  gap: var(--op-space-medium);
}

.container--admin-dashboard {
  @media (max-width: #{$breakpoint-small}) {
    min-width: 100%;
  }
}

.container--snapshot-wheel {
  max-width: 600px;

  @media (width <= $breakpoint-medium) {
    max-width: 100%;
  }
}
