@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'Gotham';
  src:url('Gotham-Book.otf');
  ascent-override: 95%;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src:url('Gotham-BookIta.otf');
  ascent-override: 95%;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src:url('Gotham-Medium.otf');
  ascent-override: 95%;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src:url('Gotham-MediumIta.otf');
  ascent-override: 95%;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src:url('Gotham-Bold.otf');
  ascent-override: 95%;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src:url('Gotham-BoldIta.otf');
  ascent-override: 95%;
  font-weight: 700;
  font-style: italic;
}
