.notification {
  display: block;
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  &:hover {
    background-color: var(--op-color-neutral-plus-eight);
    color: var(--op-color-neutral-on-plus-eight);
  }
  .discussion__label {
    background-color: var(--op-color-neutral-plus-six);
    color: var(--op-color-neutral-on-plus-six);
    box-shadow: inset var(--op-border-all) var(--op-color-neutral-plus-three);
  }
}

.notification--read {
  background-color: var(--op-color-neutral-plus-seven);
}

.notification--content {
  flex: auto;
}

.notifcation--body {
  /* to make sure urls and long works don't force an overflow */
  color: var(--op-color-neutral-on-plus-max);
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  word-break: break-word;
}

.notification__created-at {
  color: var(--op-color-neutral-on-plus-max-alt);
}

.notification--action {
  color: var(--op-color-neutral-on-plus-max);
}
