.swatch {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 13.8rem;
  height: 7.5rem;
  margin-right: var(--op-space-small);
  padding: var(--op-space-small);

  border-radius: var(--op-radius-large);
  box-shadow: var(--op-shadow-large);

  span {
    padding: var(--op-space-2x-small) var(--op-space-x-small);
    font-size: var(--op-font-x-small);
    color: var(--op-color-neutral-on-plus-six);
    border: 1px solid var(--op-color-neutral-plus-two);
    border-radius: var(--op-radius-large);
    background: var(--op-color-neutral-plus-six);
  }
}
