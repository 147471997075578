.search__result {
  mark {
    background-color: var(--op-color-primary-plus-four);
    color: var(--op-color-primary-on-plus-four);
  }
}

.search__attachments {
  display: flex;
  align-items: center;
  gap: var(--op-space-medium);
}

.search__preview  {
  font-family: var(--op-font-family-alt);
  font-size: var(--op-font-medium);
}
.search__type {
  @extend %content-label-global;
}

.search__type--content {
  box-shadow: inset var(--op-border-all) var(--op-color-type-content-plus-three);
    background-color: var(--op-color-type-content-plus-six);
    color: var(--op-color-type-content-on-plus-six);
}

.search__type--curriculum {
  box-shadow: inset var(--op-border-all) var(--op-color-type-curriculum-plus-three);
    background-color: var(--op-color-type-curriculum-plus-six);
    color: var(--op-color-type-curriculum-on-plus-six);
}
