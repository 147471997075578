*, ::after, ::before {
  box-sizing: border-box;
}

body {
  background: var(--c12-gradient-background);
  &:has(.app-with-sidebar--sidebar-hidden) {
    overflow: hidden;
    @supports (height: 100dvh) {
        min-height: 100dvh;
        height: 100dvh;
      }
    }
}

button, input, optgroup, select, textarea {
  margin: 0;

  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

p {
  font-size: var(--op-font-small);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-base);
  letter-spacing: 0em;
  margin: 0;
}

a,
button {
  font-weight: var(--op-font-weight-medium);
  color: var(--op-color-primary-original);
  letter-spacing: 0em;
  transition: var(--op-transition-input);
  &:hover {
    color: var(--op-color-primary-on-plus-six);
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--op-space-small);
}

h1 {
  font-size: var(--op-font-5x-large);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-dense);
  letter-spacing: -0.01em;
}

h2 {
  font-size: var(--op-font-4x-large);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-dense);
  letter-spacing: -0.01em;
}

h3 {
  font-size: var(--op-font-3x-large);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-base);
  letter-spacing: -0.01em;
}

h4 {
  font-size: var(--op-font-2x-large);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-loose);
  letter-spacing: 0em;
}

h5 {
  font-size: var(--op-font-x-large);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-denser);
  letter-spacing: 0em;
}

h6 {
  font-size: var(--op-font-medium);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-looser);
  letter-spacing: 0em;
}

summary::-webkit-details-marker {
  display: none;
}

.display-none {
  display: none !important;
}

.subtitle {
  font-size: var(--op-font-small);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: 19px;
  letter-spacing: 0em;
}

.subtitle-sm {
  font-size: var(--op-font-x-small);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-denser);
  letter-spacing: 0em;
}

.text-lg {
  font-size: var(--op-font-medium);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: 19px;
  letter-spacing: 0em;
}

.text {
  font-size: var(--op-font-small);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-dense);
  letter-spacing: 0em;
}

.text-sm {
  font-size: var(--op-font-x-small);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-denser);
  letter-spacing: 0em;
}

.caption {
  font-size: var(--op-font-x-small);
  font-style: normal;
  font-weight: var(--op-font-weight-normal);
  line-height: var(--op-line-height-densest);
  letter-spacing: 0em;
}

.empty-state-light {
  padding: var(--op-space-2x-large);
  font-weight: var(--op-font-weight-medium);
  font-size: var(--op-font-small);
  background: var(--op-color-neutral-plus-eight);
  color: var(--op-color-neutral-on-plus-eight);
  text-align: center;
  width: 100%;
}

.empty-state-dark {
  padding: var(--op-space-2x-large);
  font-weight: var(--op-font-weight-medium);
  font-size: var(--op-font-small);
  background: var(--op-color-neutral-minus-six);
  color: var(--op-color-neutral-on-minus-six);
  width: 100%;
}

.button-lg {
  font-size: var(--op-font-small);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
}

.button-sm {
  font-size: var(--op-font-x-small);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
}

.button-xs {
  font-size: var(--op-font-x-small);
  font-style: normal;
  font-weight: var(--op-font-weight-medium);
  line-height: var(--op-line-height-densest);
  letter-spacing: 0em;
}


a {

  text-decoration: none;
}

hr {
  background-color: var(--op-color-border);
  border: none;
  height: 1px;
  line-height: 1px;
}

.simple-format {
  p {
    margin-bottom: var(--op-space-small)
  }
}

.responsive-divider {
  margin: var(--op-space-2x-large) 0 var(--op-space-4x-large);

  @media(max-width: #{$breakpoint-medium}) {
    margin: var(--op-space-2x-large) 0 calc(var(--op-space-4x-large) - 15px);
  }

  @media(max-width: #{$breakpoint-x-small}) {
    margin: var(--op-space-small) 0 var(--op-space-2x-large);
  }
}

.responsive-spacing-stacked {
  margin-right: var(--op-space-small);

  @media(max-width: #{$breakpoint-x-small}) {
    margin-right: 0;
    margin-bottom: var(--op-space-x-small);
  }
}

.overflow-wrapper {
  width: 100%;
  overflow: scroll;
}

.overflow-wrapper-x {
  @media (max-width: #{$breakpoint-medium}) {
    width: 100%;
    overflow-x: scroll;
  }
}

.page-description {
  font-size: var(--op-font-small);
  font-weight: var(--op-font-weight-normal);
  color: var(--op-color-neutral-on-plus-eight-alt);
}

.section-title {
  margin-bottom: var(--op-space-large);
}

.button-group {
  display: flex;
  flex-direction: row;
  gap: var(--op-space-small);
  align-items: center;
  width: 100%;
  justify-content: flex-end;

  @media (max-width: #{$breakpoint-x-small}) {
    flex-direction: column;
    align-items: stretch;
  }
}

.button-group-stacked {
  @media(max-width: #{$breakpoint-x-small}) {
    flex-direction: column;
    align-items: flex-end;
  }
}

.search-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: var(--op-space-small);
}

.search-bar {
  width: 100%;
  max-width: 350px;
  display: flex;
  gap: var(--op-space-small);
  align-items: center;

  @media (max-width: #{$breakpoint-small}) {
    max-width: 100%;
  }
}

.material-symbols {
  vertical-align: -25%;
}

.list__header {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  font-family: var(--op-font-family-alt);
  font-size: var(--op-font-medium);
  font-weight: var(--op-font-weight-bold);
}

.list__items {
  display: grid;
  gap: var(--op-space-medium);
}


/* SVAS */

.svas {
  .chart-funnel__item {
    min-width: calc(38 * var(--op-size-unit)); // 152px
  }
  .bar-container {
    height: var(--op-space-small);
    margin-top: var(--op-space-small);
    width: 100%;
    border-radius: var(--op-radius-pill);
    position: relative;
    background-color: var(--op-color-neutral-plus-four);
  }
  .bar {
    height: 100%;
    border-radius: var(--op-radius-pill);
    background-color: var(--op-color-primary-minus-three);
  }
  .bar-percentage {
    position: absolute;
    right: 4px;
    top: -100%;
    text-transform: uppercase;
      font-size: var(--op-font-x-small);
      letter-spacing: var(--op-letter-spacing-label);
      font-weight: var(--op-font-weight-medium);
    color: var(--op-color-neutral-on-plus-max-alt)
  }
}

// Override wistia set height and widths
.wistia_click_to_play {
  height: auto !important;
  width: auto !important;
}

.devise-errors {
  color: var(--op-color-neutral-on-minus-max);
}

// add extra padding for mobile safari nav bar
.app-body {
  @media(max-width: #{$breakpoint-small}) {
    padding-bottom: 70px;
  }
}
