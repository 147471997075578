.tiles-2-2 {
  grid: auto auto / auto auto;
  display: grid;

  @media (max-width: #{$breakpoint-small}) {
    grid-template-columns: 1fr;
  }
}

.tile {
  border: 1px solid var(--op-color-border);
  padding: var(--op-space-2x-large);
  margin: var(--op-space-small);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: #{$breakpoint-small}) {
    padding-left: var(--op-space-x-small);
    padding-right: var(--op-space-small);
  }
}

.tile__header {
  font-size: var(--op-font-small);
  text-align: left;
}

.tile span {
  font-size: 1.2em;
}

.tile table {
  td {
    padding-top: var(--op-space-2x-small);
    padding-bottom: var(--op-space-2x-small);
  }
}
