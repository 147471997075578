.category-list {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-large);
}

.category {
  contain: none;
  overflow: visible;
  padding: var(--op-space-small) var(--op-space-x-large) var(--op-space-x-large);
  font-family: var(--op-font-family-alt);

  @media(max-width: #{$breakpoint-small}) {
    padding: var(--op-space-medium);
  }
}

.category__header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  text-decoration: none;
  gap: var(--op-space-small);
  padding-block: var(--op-space-small);
  box-shadow: var(--op-border-top) inset var(--op-color-border);

  &.category__header--borderless {
    box-shadow: none;
  }

  @container (width <=#{$breakpoint-x-small}) {
    grid-template-columns: 1fr;

    [class^="btn-"] {
      width: 100%;
    }
  }
}

.category__header-start {
  display: flex;
  align-items: center;
  gap: var(--op-space-small);
}

.category__info {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-3x-small);
  justify-self: flex-start;
}

.category__author {
  display: flex;
  gap: var(--op-space-x-small);
  align-items: center;
  font-size: var(--op-font-x-small);
  line-height: var(--op-line-height-dense);
  color: var(--op-color-on-background-alt);
}

.category__description {
  font-size: var(--op-font-small);
  line-height: var(--op-line-height-dense);
  color: var(--op-color-on-background-alt);
}

.category__avatar {
  width: var(--_op-avatar-size-medium);
}

.category__title {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-x-small);
  font-family: var(--op-font-family-alt);
  font-weight: var(--op-font-weight-bold);
  font-size: var(--op-font-large);
  line-height: var(--op-line-height-dense);
  text-decoration: none;
  color: var(--op-color-neutral-on-plus-max);
}

.category__title-link {
  font-family: var(--op-font-family-alt);
  font-weight: var(--op-font-weight-bold);
  font-size: var(--op-font-large);
  text-decoration: none;
}


.category__subtitle {
  font-size: var(--op-font-small);
  font-weight: var(--op-font-weight-light);
  color: var(--op-color-neutral-on-plus-max-alt);
}

.category__topics {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}

.category__row {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns:
    var(--avatar-size-default) // Avatar column */
    minmax(35%, 2fr) auto;
  grid-auto-flow: column;
  padding: var(--op-space-small) var(--op-space-x-small);
  gap: var(--op-space-2x-small) var(--op-space-small);
  text-decoration: none;
  transition: var(--c12-transition-slow);
  justify-items: flex-start;
  background-color: var(--op-color-neutral-plus-max);

  &:not(:last-of-type) {
    box-shadow: var(--op-border-top) inset var(--op-color-border);
  }

  .discussion__response-count {
    display: none;
  }
}

.category__row-action {
  min-width: var(--op-input-height-large);
}

.category__responses {
  width: calc((7 * var(--_op-avatar-size-medium)) / 2); // 140px
  padding-left: var(--op-space-medium);
  display: flex;
  font-size: var(--op-font-x-small);
  color: var(--op-color-on-background-alt);
  justify-content: flex-end;
}

.category__link {
  font-family: var(--op-font-family-alt);
  font-weight: var(--op-font-weight-bold);
  font-size: var(--op-font-medium);
  line-height: var(--op-line-height-dense);
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* Set the number of lines you want to display */
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.category__status-viewed,
.category__status-notification {
  svg path {
    stroke: var(--op-color-alerts-notice-base);
  }
}

.category__status {
  width: calc((var(--op-font-medium) * 3) + (var(--op-space-x-small) * 2)); // 64px 3 icons + 2 spaces
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: center;
  gap: var(--op-space-x-small);
  font-size: var(--op-font-x-small);

  .category__status-pinned {
    svg path {
      stroke: var(--op-color-primary-base);
    }
  }
}

.category__label {
  display: flex;
  align-items: center;
}

.discussion__response-count {
  display: none;
}


// on a mobile device let's change the layout to be more compact
@container (width <=#{$breakpoint-small}) {
  .category__row:not(.category__row--resource) {
    padding: var(--op-space-small) 0;
    grid-template-columns:
      var(--avatar-size-default) // Avatar column */
      minmax(50%, 3fr) max-content;

    .avatar-group,
    .category__status,
    .category__label {
      display: none;
    }

    .discussion__response-count {
      display: flex;
      align-items: center;
    }
  }

  .category__responses {
    width: var(--op-space-4x-large);
  }

}

@container (width <=#{$breakpoint-x-small}) {
  .category__row:not(.category__row--resource) {
      grid-template-columns:
        minmax(50%, 3fr) max-content;
    .category__avatar {
      display: none;
    }
  }
}
