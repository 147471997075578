.status-good {
  background: var(--op-color-alerts-notice-plus-five);
  border: var(--op-border-width) solid var(--op-color-alerts-notice-base);
  color: var(--op-color-alerts-notice-on-plus-five);
}

.status-caution {
  background: var(--op-color-alerts-warning-plus-five);
  border: var(--op-border-width) solid var(--op-color-alerts-warning-base);
  color: var(--op-color-alerts-warning-on-plus-five);
}

.status-danger {
  background: var(--op-color-alerts-danger-plus-five);
  border: var(--op-border-width) solid var(--op-color-alerts-danger-base);
  color: var(--op-color-alerts-danger-on-plus-five);
}

.status-neutral {
}

.status-notice {
  background: var(--op-color-alerts-notice-plus-five);
  color: var(--op-color-alerts-notice-on-plus-five);
  padding: var(--op-space-x-small) var(--op-space-x-small);
}
