.item--with-icon {
  display: flex !important;
  align-items: center;
  gap: var(--op-space-small);
}

.svg-select-icon {
  height: var(--_icon-width-large);
  width: var(--_icon-width-large);
}
