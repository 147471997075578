table {
  width: 100%;
  border-collapse: collapse;
  background: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  border: var(--op-border-width) solid var(--op-color-border);

  thead {
    background: var(--op-color-neutral-plus-max);
    color: var(--op-color-neutral-on-plus-five);
    font-size: var(--op-font-small);
  }

  th {
    padding: var(--op-space-large);

    text-align: left;

    color: var(--op-color-neutral-on-plus-six);

    text-transform: uppercase;
    font-size: var(--op-font-small);
    font-weight: 500;
  }

  tbody {
    padding: var(--op-space-x-small);
    border: var(--op-border-width) double var(--op-color-border);
    // border-style: double;

    tr {
      padding: var(--op-space-small);
      border-top: 1px solid var(--op-color-border);
      transition: var(--c12-transition-fast);

      // TODO enable if/when more of the table rows are clickable
      // &:hover {
      //   background-color: var(--op-color-border);
      //   cursor: pointer;
      // }
    }

    td {
      font-weight: 400;
    }
  }

  td {
    padding: var(--op-space-large);

    color: var(--op-color-on-background);
    font-size: var(--op-font-small);
  }

  tfoot {
    background: var(--op-color-neutral-plus-max);
    color: var(--op-color-neutral-on-plus-max);
    border: var(--op-border-width) double var(--op-color-border);
    font-weight: 500;
  }

  &.inside-card {
    border: none;

    tbody {
      border: none;
    }
  }
}

.table-totals {
  border-top: 1px solid var(--op-color-primary-plus-three);

  td {
    padding-top: var(--op-space-large);
    padding-bottom: var(--op-space-large);
  }
}

.table-split {
  border-bottom: 1px solid var(--op-color-border);

  td {
    padding-top: var(--op-space-2x-large);
    padding-bottom: var(--op-space-2x-large);
  }
}

.table--whitespace {
  border: none;

  thead {
    background: none;
  }

  th {
    color: var(--op-color-on-background);

    text-transform: none;

    padding: var(--op-space-large);
  }

  td {
    padding: var(--op-space-small) var(--op-space-large);
  }
}

.table--invisible {
  border: none;

  tbody {
    border: none;
  }

  tr:first-child td {
    padding-top: var(--op-space-small);
  }

  tr {
    border: none;
    padding: 0;

    &:hover {
      background: none;
      cursor: inherit;
    }
  }

  thead {
    background: none;
    padding-bottom: var(--op-space-large);
  }

  th {
    padding: 0;
  }

  td {
    padding: 0;
  }
}

.column-header {
  color: var(--op-color-on-background);
}

.column-lg {
  display: inline-block;
  width: 80%;
  flex-grow: 1;
  flex-shrink: 0;
}

.column-md {
  display: inline-block;
  width: 100%;
  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  flex-shrink: 0;
}

.column-sm {
  display: inline-block;
  width: 100%;
  max-width: 100px;
  flex-grow: 1;
  flex-shrink: 0;
}

.column-xs {
  display: inline-block;
  width: 100%;
  max-width: 50px;
  flex-grow: 1;
  flex-shrink: 0;
}

.fixed-table {
  position: relative;
}

.fixed-table__columns {
  overflow-x: scroll;
  display: flex;
  flex-flow: nowrap;
  white-space: nowrap;
  margin-left: var(--fixed-table-width);
}

.fixed-table__columns--lg {
  margin-left: var(--fixed-table-width-lg);
}

.fixed-table__column {
  flex: 1 0 var(--fixed-table-width);
  white-space: nowrap;
  text-align: center;

  &:hover {
    background: var(--op-color-neutral-plus-six);
  }
}

.fixed-table__column--sm {
  flex-basis: var(--fixed-table-width-sm);
}

.fixed-table__header {
  white-space: nowrap;
  border-bottom: 1px solid var(--op-color-border);
  height: var(--fixed-cell-wrapper-height);
  padding: var(--op-space-2x-large);
  font-size: var(--op-font-small);
  font-weight: 400;
  display: flex;
  align-items: center;
}

.fixed-table__sidebar {
  white-space: nowrap;
  position: absolute;
  margin-top: calc(var(--fixed-cell-wrapper-height) - 1px);
  left: 0;
  width: var(--fixed-table-width);
  border-right: 1px solid var(--op-color-border);
  border-top: 1px solid var(--op-color-border);
}

.fixed-table__sidebar--lg {
  width: var(--fixed-table-width-lg);
}

.fixed-table__cell__sidebar {
  white-space: nowrap;
  height: var(--fixed-cell-wrapper-height);
  padding: var(--op-space-2x-large) 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: var(--op-font-small);
  font-weight: 500;
  box-shadow: var(--op-border-top) inset var(--op-color-border);
}

.fixed-table__cell-wrapper {
  box-shadow: var(--op-border-top) inset var(--op-color-border);
  padding: var(--op-space-x-small) 0;
  height: var(--fixed-cell-wrapper-height);
}

.fixed-table__cell {
  white-space: nowrap;
  height: var(--fixed-cell-height);
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--op-font-small);
  font-weight: 500;
  padding: var(--op-space-large);
}

.fixed-table__cell--filled {
  width: calc(100% - var(--op-space-small));
}
