%sidebar-global {
  --_op-sidebar-rail-width: calc(24 * var(--op-size-unit)); // 96px;
  --_op-sidebar-drawer-width: calc(66 * var(--op-size-unit)); // 264px;
  --_op-sidebar-brand-spacing: 2rem 0;
  --_op-sidebar-spacing: var(--op-space-medium);
  --_op-sidebar-drawer-brand-width: calc(21 * var(--op-size-unit)); // 88px
  --_op-sidebar-content-spacing: var(--op-space-small);
  --_op-sidebar-border-color: var(--sidebar-border-color);
  --_op-sidebar-background-color: var(--sidebar-color);
  --_op-sidebar-text-color: var(--sidebar-on-color);

  height: 100vh;
  max-height: 100vh;
  box-shadow: inset var(--op-border-left) var(--_op-sidebar-border-color);

  .sidebar__brand {
    margin: 0 calc(var(--_op-sidebar-content-spacing) + var(--op-space-small));
  }

  .sidebar__content {
    .material-symbols-outlined {
      --op-mso-weight: var(--op-font-weight-light);
      font-size: var(--op-font-2x-large);
      width: var(--op-font-2x-large);
      height: var(--op-font-2x-large);
    }

    &.sidebar__content--scrollable {
      overflow-y: auto;
      padding-bottom: var(--op-space-medium);
    }
  }

  @media (width <=$breakpoint-small) {
    --_op-sidebar-spacing: 0;
  }

}


.sidebar_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sidebar {
  overflow-y: auto;
  .btn.btn--no-border {
    color: inherit;
    text-transform: none;
    transition: var(--c12-transition-slow);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: var(--op-radius-small);
    font-weight: var(--op-font-weight-normal);

    @media(max-width: #{$breakpoint-x-small}) {
      padding: var(--op-space-small);
    }

    &:hover {
      background: var(--op-color-tertiary-plus-max);
      color: var(--op-color-tertiary-on-plus-max);
      box-shadow: inset var(--op-border-all) transparent;
    }

    // Focus State
    &:focus,
    &:focus-within,
    &:focus-visible {
      background: var(--op-color-tertiary-minus-four);
      color: var(--op-color-tertiary-on-minus-four);
      box-shadow: inset var(--op-border-all) var(--op-color-tertiary-minus-three);
    }

    &.btn--active {
      background: var(--op-color-tertiary-plus-max);
      color: var(--op-color-tertiary-on-plus-max-alt);
      box-shadow: inset var(--op-border-all) transparent;

      .material-symbols-outlined {
        color: var(--op-color-secondary-base);
      }
    }
  }
}

// This is needed for the mobile app since optics is depentant on the .sidebar class begin applied. and we're not loading the sidebar in the mobile app
.mobile-navigation-content {
  display: flex;
  flex-direction: column;
  padding: 0 var(--_op-sidebar-content-spacing);
  gap: var(--_op-sidebar-content-item-spacing);

  .btn.btn--no-border {
    color: var(--op-color-tertiary-plus-max);
    font-size: var(--op-font-medium);
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: var(--op-radius-small);
    .material-symbols-outlined {
      font-size: var(--op-font-x-large);
    }

    &:hover {
      background: transparent;
      color: var(--op-color-tertiary-plus-max);
      box-shadow: none;
    }
  }

  > * {
    justify-content: flex-start;
  }

  .subtitle,
  .identity__title {
    font-size: var(--op-font-x-small);
  }
}

.sidebar__icon {
  display: none;
  color: var(--op-color-tertiary-minus-max);
  margin: 0;
  padding: var(--op-space-medium);

  .material-symbols-outlined {
    font-size: var(--op-font-2x-large);
  }

  @media (width <= $breakpoint-small) {
    display: flex;
  }
}

.sidebar__icon--close {
  display: none;
  position: relative;
  color: var(--_op-sidebar-text-color);
  margin: 0;
}

.sidebar__input {
  display: none;
}

// Makes sidebar collapsible
@media (width <= $breakpoint-small) {

  // set the max with and width to 0 to hide the sidebar
  .sidebar.sidebar--drawer.sidebar--responsive {
    width: 0px;
    max-width: 0px;
    min-width: 0px;
    opacity: 0;
    transition: var(--_op-sidebar-transition);
    overflow: hidden;
  }

  // This adds extra bottom padding on mobile browsers
  .sidebar.sidebar--padded {
    padding-bottom: var(--op-space-4x-large);
  }

  // This change things when the input is checked
  .app-with-sidebar:has(.sidebar__input:checked) nav[role="off-canvas"] {
    width: var(--_op-sidebar-drawer-width);
    max-width: var(--_op-sidebar-drawer-width);
    min-width: var(--_op-sidebar-drawer-width);
    opacity: 1;
    overflow: auto;

    // Show the close icon in the sidebar
    .sidebar__icon--close {
      display: block;
    }

    // Set the width to normal when open
    &.sidebar.sidebar--drawer.sidebar--responsive {
      max-width: var(--_op-sidebar-drawer-width);
      @include sidebar-drawer-global;
    }

    // Undo all the rail styles when the sidebar is open
    &.sidebar--drawer.sidebar--responsive .sidebar__content {
      align-items: flex-start;

      > * {
        width: 100%;
        min-width: var(--__op-btn-height);
        justify-content: flex-start;
        padding: 0;
        font-size: var(--__op-btn-font-size);
        gap: 0;
      }

      .btn--no-border {
        padding: var(--__op-btn-padding);
        gap: var(--op-space-x-small);
      }
    }

    .subtitle,
    .identity__title {
      font-size: var(--op-font-x-small);
    }

    .identity {
      gap: var(--op-space-small);
    }

    .identity__tray--open {
      position: absolute;
    }

  }
}

.copyright {
  display: flex;
  gap: var(--op-space-small);
  justify-content: center;
  color: var(--op-color-on-background-alt);
  font-size: var(--op-font-small);
  font-weight: 400;
  @media (width < #{$breakpoint-small}) {
    flex-direction: column;
  }
}
