.react-pdf__Page {
  canvas {
    border: 1px solid var(--op-color-border);
    border-bottom: 0;
  }
}

.react-pdf__Page-Controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--op-color-neutral-on-plus-five);
  font-weight: bold;
  background-color: var(--op-color-neutral-plus-five);
  .react-pdf__Pagination {
    justify-self: center;
  }
  .btn.btn--slider-controls:not(.disabled) {
    color: var(--op-color-neutral-on-plus-five);
  }
}

// hide the annotates for now as inputs don't show up correctly
// in the future, there might be a better way to do this
.react-pdf__Page__annotations {
  display: none;
}

.react-pdf__Page__textContent {
  display: none;
}
