.report-nav__wrapper {
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: var(--op-space-large);
  height: fit-content;
  max-height: 100vh;
}

.report-nav {
  --c12-report-sidebar-width: calc(var(--op-space-scale-unit) * 32); // 320px

  display: flex;
  flex-grow: 1;
  overflow: auto;
  width: var(--c12-report-sidebar-width);
  flex-direction: column;
  justify-content: flex-start;
  background: var(--op-color-primary-minus-five);
  box-shadow: var(--op-shadow-x-large);
  border-radius: var(--op-radius-large);
  color: var(--op-color-primary-on-minus-five);
}

.report-nav__header {
  display: grid;
  grid-template-areas: 'logo title' 'logo subtitle';
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--op-space-small);
  grid-row-gap: var(--op-space-3x-small);
  align-items: center;
  line-height: var(--op-line-height-denser);

  .report-nav__logo {
    grid-area: logo;
  }

  .report-nav__title {
    grid-area: title;
  }

  .report-nav__subtitle {
    grid-area: subtitle;
  }
}

.report-nav__title {
  display: block;
  font-weight: var(--op-font-weight-bold);
}

.report-nav__subtitle {
  display: block;
  font-size: var(--op-font-small);
}

.report-nav__subtitle-sm {
  display: block;
  font-size: var(--op-font-x-small);
  font-weight: var(--op-font-weight-semi-bold);
}

.report-nav__header,
.report-nav__content {
  padding: var(--op-space-medium) var(--op-space-medium);
}

.report-nav__steps {
  padding: var(--op-space-small) var(--op-space-medium);
  border-top: var(--op-border-width) solid var(--op-color-primary-plus-two);
  display: flex;
  flex-direction: column;
  gap: var(--op-space-small);

  ol {
    list-style: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: var(--op-space-2x-small);
  }

  li {
    counter-increment: item
  }
}

.report-nav__item {
  display: flex;
  line-height: var(--op-line-height-none);
  font-weight: var(--op-font-weight-semi-bold);
  justify-content: flex-start;
  align-items: center;
  padding: var(--op-space-x-small);
  border-radius: var(--op-radius-large);
  text-decoration: none;
  color: var(--op-color-primary-on-minus-five);
  transition: var(--c12-transition-fast);

  &:hover {
    background: var(--op-color-primary-minus-three);
    color: var(--op-color-primary-on-minus-three);
  }

  &:before {
    margin-right: var(--op-space-small);
    content: counter(item);
    border-radius: 100%;
    background-color: transparent;
    border: 2px solid var(--op-color-primary-on-minus-five);
    color: var(--op-color-primary-on-minus-five);
    height: 28px;
    width: 28px;
    font-size: var(--text-sm);
    font-weight: 500;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.report-nav__item--complete {
    color: var(--op-color-primary-on-minus-five);

    &:before {
      background: var(--op-color-primary-plus-max);
      color: var(--op-color-primary-on-plus-max);
    }
  }

  &.report-nav__item--active {
    background: var(--op-color-primary-plus-max);
    color: var(--op-color-primary-on-plus-max);

    &:hover {
      background: var(--op-color-primary-minus-three);
      color: var(--op-color-primary-on-minus-three);
    }

    &:before {
      background-color: var(--op-color-primary-minus-five);
      color: var(--op-color-primary-on-minus-five);
      border: 2px solid var(--op-color-primary-minus-five);
    }
  }
}

.report-nav__back {
  position: sticky;
  padding: var(--op-space-medium) var(--op-space-medium);
  bottom: 0;
  background: var(--op-color-primary-minus-five);
  width: 100%;
  border-top: var(--op-border-width) solid var(--op-color-primary-plus-two);
}
