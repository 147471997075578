[data-bridge-components~="form"] [data-controller~="bridge--form"] [type="submit"] {
  display: none;
}
[data-bridge-components~="form"] [data-controller~="bridge--form"] [data-bridge--form-target="cancel"] {
  display: none;
}

[data-bridge-components~="header-actions"] [data-controller~="bridge--header-actions"] [data-bridge--header-actions-target="item"] {
  display: none;
}
