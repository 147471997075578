.app-body:has(.login-wrapper) {
  background: var(--op-color-primary-plus-seven);
}

.login-wrapper {
  min-height: 100%;
}

.wrapper--native .login-wrapper {
  background-color: transparent;
}

.login-content {
  padding: var(--op-space-4x-large);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-width: 325px;
  max-width: 1100px;

  @media(max-width: #{$breakpoint-medium}) {
    flex-direction: column;
    padding: var(--op-space-large);
  }

  .login-title {
    margin-right: var(--op-space-4x-large);

    h1 {
      color: var(--op-color-primary-on-plus-seven-alt);
    }

    h4 {
      color: var(--op-color-primary-on-plus-seven-alt);
    }

    @media(max-width: #{$breakpoint-medium}) {
      max-width: 325px;
      margin: var(--op-space-large) 0;

      h1,
      h4 {
        text-align: center;
      }

      h1 {
        font-size: var(--op-font-3x-large);
      }

      h4 {
        font-size: var(--op-font-medium);
        line-height: calc(var(--op-font-medium) + .5em);
      }
    }

    @media(max-width: #{$breakpoint-small}) {
      min-width: 100%;
    }
  }

  .card--themed {
    min-width: 325px;

    @media(max-width: #{$breakpoint-small}) {
      min-width: 100%;
    }

    .btn {
      width: 100%;
    }
  }

  .form-group {
    margin-top: 0;

    label {
      color: var(--op-color-primary-on-minus-five);
    }

    .form-control {
      background-color: transparent;
      border-color: var(--op-color-primary-plus-three);
      color: var(--op-color-primary-on-minus-five);

      &:focus {
        box-shadow: 0 0 0 3px var(--op-color-primary-plus-one);
      }
    }

    .btn {
      width: 100%;
    }

    .btn-primary {
      background-color: var(--op-color-primary-minus-two);
      color: var(--op-color-primary-on-minus-two);
      box-shadow: inset var(--op-border-all) var(--op-color-primary-minus-one);

      &:hover {
        background: var(--op-color-primary-minus-one);
        color: var(--op-color-primary-on-minus-one);
        box-shadow: inset var(--op-border-all) var(--op-color-primary-minus-three);
      }
    }
  }

  .remembe-me__group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--op-space-large) 0;

    label {
      margin: 0;

      span {
        font-size: var(--op-font-small);
      }
    }
  }

  .btn.btn--no-border {
    color: var(--op-color-primary-on-minus-five);
    width: 100%;

    a {
      color: inherit;
    }

    &:hover {
      background: var(--op-color-primary-minus-one);
      color: var(--op-color-primary-on-minus-one);
      box-shadow: inset var(--op-border-all) var(--op-color-primary-minus-three);
    }
  }
}
