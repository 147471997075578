.panel {
  height: 100vh;
  width: var(--panel-width);
  z-index: 100;
  position: absolute;
  right: calc(-1 * var(--panel-width)); // this pushes the panel off the sceen to the right
  background: white;
  transition: right var(--panel-transition-speed);

  overflow-y: scroll;
  padding-bottom: var(--op-space-large);
}

.panel--active {
  right: 0;
  box-shadow: var(--panel-shadow);
}

.panel__close {
  float: right;
  cursor: pointer;
}
