.toggle {
  border: 1px solid var(--op-color-primary-minus-three);
  border-radius: var(--op-radius-large);
  padding: var(--op-space-x-small);
}

.toggle__option {
  border-radius: var(--op-radius-large);
  padding: var(--op-space-small);
}

.toggle__option--active {
  background: var(--op-color-primary-minus-three);
  color: var(--op-color-primary-on-minus-three);
}
