.pill {
  border-radius: var(--op-radius-pill);
  background-color: var(--op-color-primary-plus-five);
  border: var(--op-border-width) solid var(--op-color-primary-base);
  padding: 0 var(--op-space-x-small);
  font-size: var(--op-font-x-small);
  letter-spacing: var(--op-letter-spacing-label);
}

.pill--danger {
  color: var(--op-color-alerts-danger-on-plus-five);
  background: var(--op-color-alerts-danger-plus-five);
  border: var(--op-border-width) solid var(--op-color-alerts-danger-base)
}

