%attachment-captions {
  border: none !important;
  padding: var(--op-space-x-small) !important;
  background-color: var(--op-color-primary-plus-seven);
  color: var(--op-color-primary-on-plus-seven-alt);
  border-radius: var(--op-radius-large);
  line-height: var(--op-line-height-denser);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-four);
  display: inline-flex;
  gap: var(--op-space-2x-small);

  &:hover {
    background-color: var(--op-color-primary-plus-six);
    color: var(--op-color-primary-on-plus-six);

    a {
      color: var(--attachment-link-on-color);
    }
  }
}

trix-toolbar {
  --trix-icon-width: calc(var(--op-size-unit) * 7); // 28px
  --trix-icon-height: calc(var(--op-size-unit) * 8); // 40px
  --trix-icon-color: var(--op-color-neutral-on-plus-eight);

  .trix-dialog {
    font-size: var(--op-font-small);
    padding: var(--op-space-medium);
    border: none;
    background: #fff;
    box-shadow: var(--op-shadow-large), var(--c12-shadow-small);
    border-radius: 0;
    z-index: var(--op-z-index-dialog);
  }

  @container (width < #{$breakpoint-x-small}) {
    &#trix-toolbar-1 {
      background-color: transparent;
      overflow-x: scroll;
      white-space: nowrap;
      width: 100%;
    }

    .trix-button-group--block-tools {
      display: none;
    }

    ::-webkit-scrollbar {
      display: none;
    }

    .trix-button-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
    .trix-button--icon {
      height: var(--trix-icon-height);
      max-width: calc(var(--trix-icon-width) + 3.5vw);
    }
  }

  .trix-button-group {
    background-color: var(--op-color-neutral-plus-eight);
    border: var(--op-border-width) solid var(--op-color-border);
    border-top-color: var(--op-color-border);
    border-bottom-color: var(--op-color-border);
  }

  .trix-button {
    border-bottom: none;
    border-color: var(--op-color-border);

    &:not(:first-child) {
        border-left: 1px solid var(--op-color-border);
    }

    &.trix-active {
      background: var(--op-color-primary-plus-four);
      color: ar(--op-color-primar-on-plus-four);
    }

    &--icon:disabled::before {
      opacity: .5;
    }

    &--icon::before {
      background-image: unset;
      background-color: var(--trix-icon-color);
      mask-position: center;
      mask-repeat: no-repeat;
      opacity: 0.9;
      top: .2em;
      bottom: .2em;
    }

    &--icon-table::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Table--Streamline-Sharp'%3E%3Cdesc%3ETable Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg %3E%3Cpath id='Rectangle 433' stroke='%23000000' d='M1.8625 1.8625h18.625v18.625H1.8625z' stroke-width='2'%3E%3C/path%3E%3Cpath id='vector 2077' stroke='%23000000' d='M8.070678124999999 1.8625v18.625' stroke-width='2'%3E%3C/path%3E%3Cpath id='vector 2078' stroke='%23000000' d='m20.4875 8.070678124999999 -18.625 0' stroke-width='2'%3E%3C/path%3E%3Cpath id='vector 2079' stroke='%23000000' d='m20.4875 14.27885625 -18.625 0' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-underline::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Underline-Text-3--Streamline-Sharp'%3E%3Cdesc%3EUnderline Text 3 Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg %3E%3Cpath id='Vector 2127' stroke='%23000000' d='M0.93125 20.4875h20.4875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 73' stroke='%23000000' d='M4.6564176250000004 6.51875V1.8625H17.69393625v4.65625' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2128' stroke='%23000000' d='M6.51875 16.7625h9.3125' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2129' stroke='%23000000' d='M11.17518625 1.8625v14.9' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-bold::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Bold-Text--Streamline-Sharp'%3E%3Cdesc%3EBold Text Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='bold-text--text-bold-formatting-format'%3E%3Cpath stroke='%23000000' d='M4.703650625000001 10.345349375V1.2190993749999999H13.1973025C14.760964375 1.2190993749999999 16.02848875 2.519124375 16.02848875 4.122923125V7.441525625C16.02848875 9.045231249999999 14.760871250000001 10.345349375 13.1973025 10.345349375H4.703650625000001ZM4.703650625000001 10.345349375L4.703650625000001 21.130900625H14.815163125C16.378731875000003 21.130900625 17.646349375000003 19.830875624999997 17.646349375000003 18.227076875V13.24908C17.646349375000003 11.645374375000001 16.378731875000003 10.345349375 14.815163125 10.345349375H4.703650625000001Z' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-italic::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Italic-Text--Streamline-Sharp'%3E%3Cdesc%3EItalic Text Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='italic-text--text-formatting-italic-format'%3E%3Cpath id='Vector 2111' stroke='%23000000' d='M21.41875 1.8625H8.38125' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2113' stroke='%23000000' d='M14.9 1.8625 7.45 20.4875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2112' stroke='%23000000' d='M13.96875 20.4875H0.93125' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-strike::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Strikethrough-1--Streamline-Sharp'%3E%3Cdesc%3EStrikethrough 1 Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='strikethrough-1--text-strike-through-formatting-format'%3E%3Cpath id='Vector 72' stroke='%23000000' d='m0.9315014375 11.175 20.487527937499998 0' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 73' stroke='%23000000' d='M17.228125000000002 6.5212923125V4.578621C17.228125000000002 3.0785541875 16.01209875 1.8625 14.51204125 1.8625L7.8379959999999995 1.8625C6.337919875 1.8625 5.121865687500001 3.0783865625 5.121875 4.5784626875l0.0000093125 1.8433535C5.121893625 7.62898625 5.918624562500001 8.691170000000001 7.0775 9.0291765L14.166268125 11.175' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 74' stroke='%23000000' d='M17.228218125 13.96875v3.803690625c-0.000093125 1.5000575 -1.2161193750000001 2.714873125 -2.716176875 2.714873125l-6.674026625 0c-1.5000761249999999 0 -2.7161210000000002 -1.21602625 -2.7161210000000002 -2.71608375V15.829387500000001' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-link::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Link-Chain--Streamline-Sharp'%3E%3Cdesc%3ELink Chain Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='link-chain--create-hyperlink-link-make-unlink-connection-chain'%3E%3Cpath id='Vector 47' stroke='%23000000' d='m14.9 7.45 -7.45 7.45' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 48' stroke='%23000000' d='m9.3125 6.51875 5.121875 -5.121875 6.51875 6.51875L15.83125 13.0375' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 1348' stroke='%23000000' d='m13.0375 15.83125 -5.121875 5.121875 -6.51875 -6.51875L6.51875 9.3125' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-heading-1::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Heading-1-Paragraph-Styles-Heading--Streamline-Sharp'%3E%3Cdesc%3EHeading 1 Paragraph Styles Heading Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='heading-1-paragraph-styles-heading'%3E%3Cpath id='Vector 1' stroke='%23000000' d='M0.93125 21.41875V0.93125' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 3' stroke='%23000000' d='M12.106250000000001 0.93125v20.4875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2' stroke='%23000000' d='M0.93125 10.4765625h11.175' stroke-width='2'%3E%3C/path%3E%3Cpath stroke='%23000000' d='m19.090625 20.4875 0 -9.3125c0 1.285776875 -1.094498125 2.328125 -2.4445312500000003 2.328125H15.83125' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector_2' stroke='%23000000' d='M22.35 20.4875h-6.51875' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-quote::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Quotation-3--Streamline-Sharp'%3E%3Cdesc%3EQuotation 3 Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='quotation-3--quote-quotation-format-formatting-open-close-marks-text'%3E%3Cpath id='Vector 2124' stroke='%23000000' d='m1.8625 5.1223313125 4.190625 0 0 4.1903549375 -4.190625 0L1.8625 5.5875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2125' stroke='%23000000' d='M5.5875 1.396875c-2.057261625 0 -3.725 1.6677383749999999 -3.725 3.725L1.8625 5.5875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2126' stroke='%23000000' d='m10.24375 5.1223313125 4.190625 0 0 4.1903549375 -4.190625 0L10.24375 5.5875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2127' stroke='%23000000' d='M13.96875 1.396875c-2.057224375 0 -3.725 1.6677383749999999 -3.725 3.725l0 0.465625' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2128' stroke='%23000000' d='m20.4875 17.228125000000002 -4.190625 0 0 -4.190438749999999 4.190625 0 0 3.7252793750000004' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2129' stroke='%23000000' d='M16.7625 20.953125c2.057224375 0 3.725 -1.667775625 3.725 -3.725l0 -0.465625' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2130' stroke='%23000000' d='m12.106250000000001 17.228125000000002 -4.190625 0 0 -4.190438749999999 4.190625 0 0 3.7252793750000004' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2131' stroke='%23000000' d='M8.38125 20.953125c2.057224375 0 3.725 -1.667775625 3.725 -3.725l0 -0.465625' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-code::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Code-Bracket--Streamline-Sharp'%3E%3Cdesc%3ECode Bracket Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='code-bracket'%3E%3Cpath id='Vector 2071' stroke='%23000000' d='M5.521195 7.246615L1.168811875 11.598998125L5.521195 15.951474375000002' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2072' stroke='%23000000' d='M16.828805000000003 7.246615L21.181188125000002 11.598998125L16.828805000000003 15.951474375000002' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 4188' stroke='%23000000' d='M7.861705625 18.786385625L14.478888750000001 3.5636143750000002' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-bullet-list::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Bullet-List--Streamline-Sharp'%3E%3Cdesc%3EBullet List Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='bullet-list--points-bullet-unordered-list-lists-bullets'%3E%3Cpath id='Rectangle 944' stroke='%23000000' d='M1.8625 1.8625h3.725v3.725H1.8625z' stroke-width='2'%3E%3C/path%3E%3Cpath id='Rectangle 945' stroke='%23000000' d='M1.8625 9.3125h3.725v3.725H1.8625v-3.725Z' stroke-width='2'%3E%3C/path%3E%3Cpath id='Rectangle 946' stroke='%23000000' d='M1.8625 16.7625h3.725v3.725H1.8625z' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2101' stroke='%23000000' d='M8.38125 3.725h13.0375' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2102' stroke='%23000000' d='M8.38125 11.175h13.0375' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2103' stroke='%23000000' d='M8.38125 18.625h13.0375' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-number-list::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-0.825 -0.825 24 24' id='List-Numbers--Streamline-Ultimate'%3E%3Cdesc%3EList Numbers Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg%3E%3Cpath d='m7.6828125 2.79375 13.96875 0' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3C/path%3E%3Cpath d='m7.6828125 11.175 13.96875 0' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3C/path%3E%3Cpath d='m7.6828125 19.556250000000002 13.96875 0' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3C/path%3E%3Cpath d='M0.7450000000000001 17.09775a1.396875 1.396875 0 1 1 1.3503125 1.7600624999999999 1.396875 1.396875 0 1 1 -1.3503125 1.769375' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3C/path%3E%3Cpath d='M0.6984375 10.4765625a1.396875 1.396875 0 0 1 1.396875 -1.396875h0a1.396875 1.396875 0 0 1 1.396875 1.396875h0a2.0115000000000003 2.0115000000000003 0 0 1 -0.4376875 1.247875L0.6984375 13.270312500000001h2.79375' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3C/path%3E%3Cg%3E%3Cpath d='M0.6984375 0.6984375h0.6984375a0.70775 0.70775 0 0 1 0.6984375 0.6984375v4.8890625000000005' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3C/path%3E%3Cpath d='m0.6984375 6.2859375 2.79375 0' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-decrease-nesting-level::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Decrease-Indent--Streamline-Sharp'%3E%3Cdesc%3EDecrease Indent Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='decrease-indent--text-alignment-indent-paragraph-align-formatting-decrease'%3E%3Cpath id='Vector 1537' stroke='%23000000' d='m6.984375 5.5875 -5.5875 5.5875 5.5875 5.5875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2101' stroke='%23000000' d='M0.93125 1.8625h20.4875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2102' stroke='%23000000' d='M10.24375 6.51875h11.175' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2103' stroke='%23000000' d='M10.24375 11.175h11.175' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2104' stroke='%23000000' d='M10.24375 15.83125h11.175' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2105' stroke='%23000000' d='M0.93125 20.4875h20.4875' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-increase-nesting-level::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Increase-Indent--Streamline-Sharp'%3E%3Cdesc%3EIncrease Indent Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='increase-indent--alignment-align-indent-paragraph-increase-formatting-text'%3E%3Cpath id='Vector 2101' stroke='%23000000' d='M0.93125 1.8625h20.4875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 1537' stroke='%23000000' d='m1.396875 5.5875 5.5875 5.5875 -5.5875 5.5875' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2102' stroke='%23000000' d='M10.24375 6.51875h11.175' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2103' stroke='%23000000' d='M10.24375 11.175h11.175' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2104' stroke='%23000000' d='M10.24375 15.83125h11.175' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2105' stroke='%23000000' d='M0.93125 20.4875h20.4875' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-attach::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Paperclip-1--Streamline-Sharp'%3E%3Cdesc%3EPaperclip 1 Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='paperclip-1--attachment-link-paperclip-unlink'%3E%3Cpath id='Vector 1471' stroke='%23000000' d='m11.451674375000001 20.4875 7.6100818750000006 -7.606636250000001c2.521731875 -2.520614375 2.521731875 -6.607293250000001 0 -9.127907625 -2.521825 -2.5206050625 -6.610385 -2.5206050625 -9.132116875000001 0L2.6577595625 11.02153c-1.6811763125 1.6803475 -1.6811763125 4.4048125 0 6.085253125 1.681167 1.6804406250000001 4.4068891875 1.6804406250000001 6.0880655 0l7.2718611875 -7.268499375c0.8405462499999999 -0.8402389375 0.8405462499999999 -2.20248075 0 -3.0426824375000003 -0.8405462499999999 -0.8402016875 -2.2034306249999998 -0.8402016875 -3.04407 0L5.7017923125 14.06411' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-undo::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Line-Arrow-Turn-Backward--Streamline-Sharp'%3E%3Cdesc%3ELine Arrow Turn Backward Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='line-arrow-turn-backward--arrow-bend-curve-change-direction-return-left-back-backward'%3E%3Cpath id='Vector 2249' stroke='%23000000' d='M1.396875 8.38125 15.83125 8.38125c3.085883125 0 5.5875 2.5016168750000003 5.5875 5.5875s-2.5016168750000003 5.5875 -5.5875 5.5875l-4.65625 0' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2250' stroke='%23000000' d='m6.984375 13.96875 -5.5875 -5.5875 5.5875 -5.5875' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }

    &--icon-redo::before {
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='-0.825 -0.825 24 24' id='Line-Arrow-Turn-Forward--Streamline-Sharp'%3E%3Cdesc%3ELine Arrow Turn Forward Streamline Icon: https://streamlinehq.com%3C/desc%3E%3Cg id='line-arrow-turn-forward--arrow-bend-curve-change-direction-return-right-next-forward'%3E%3Cpath id='Vector 2249' stroke='%23000000' d='M20.953125 8.38125 6.51875 8.38125c-3.0858924375 0 -5.5875 2.5016168750000003 -5.5875 5.5875s2.5016075625000003 5.5875 5.5875 5.5875l4.65625 0' stroke-width='2'%3E%3C/path%3E%3Cpath id='Vector 2250' stroke='%23000000' d='m15.365625 13.96875 5.5875 -5.5875 -5.5875 -5.5875' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

// We need to override trix.css’s image gallery styles to accommodate the
// <action-text-attachment> element we wrap around attachments. Otherwise,
// images in galleries will be squished by the max-width: 33%; rule.
.trix-content {
  .attachment-gallery {
    display: grid !important;
    [data-trix-cursor-target] {
      position: absolute;
    }

    .attachment {
      flex: none !important;
      max-width: 100% !important;
    }

    &.attachment-gallery--2,
    &.attachment-gallery--4 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.attachment-gallery--3,
    &.attachment-gallery--5,
    &.attachment-gallery--6 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

trix-editor {
  figure > img {
    display: none;
  }

  .attachment__caption {
    @extend %attachment-captions;
  }
}

.attachment.attachment--file {
  @extend %attachment-captions;
  display: inline-flex;
  gap: var(--op-space-small);
  margin: var(--op-space-x-small);
}

.attachment {
  display: inline-flex;
  align-items: center;
  gap: var(--op-space-2x-small);

  &.attachment--missing {
    font-weight: 500;
    cursor: not-allowed;
  }

  .attachment__caption {
    display: inline-flex;
    align-items: center;
    gap: var(--op-space-2x-small);
    .caption {
      display: inline-flex;
      align-items: center;
      gap: var(--op-space-2x-small);
      font-size: var(--op-font-small);
    }

    .actions {
      display: inline-flex;
      gap: var(--op-space-2x-small);
      align-items: center;
      margin-left: var(--op-space-2x-small);
      padding-left: var(--op-space-2x-small);
      box-shadow: var(--op-border-left) var(--op-color-primary-plus-four);

      font-size: var(--op-font-small);

      .btn-primary {
        color: var(--op-color-primary-plus-seven);

        a {
          display: inline-flex;
          align-items: center;
          gap: var(--op-space-2x-small);
          color: var(--op-color-primary-on-plus-seven);
        }
      }
    }
  }

  &.attachment--content {
    display: inline-block;
  }
}

.attachment__toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

.attachments__preview {
  display: flex;
  flex-wrap: wrap;
  &>* {
    margin: var(--op-space-x-small) var(--op-space-x-small) 0 0;
  }
}
