.app-with-sidebar {
  transition: var(--_op-sidebar-transition);
}

.app-with-sidebar--sidebar-hidden {
  grid-template-columns: 1fr;
  height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  .app__header {
    grid-template-columns: auto;
  }
  .page-header__title {
    display: none;
  }

  .page-header {
    padding: 0;
  }

  .page-header__actions, .page-header p {
    padding: var(--op-space-small);
  }

  .app__content {
    padding-bottom: var(--op-space-4x-large);
  }

  .search-bar {
    max-width: 100%;
  }
}

@media (width <=$breakpoint-small) {

  // sidebar column is 0 on a small screen
  .app-with-sidebar:not(.app-with-sidebar--sidebar-hidden) {
    grid-template-columns: 0 1fr 0;

    // change the template columns to hide the sidebar
    &:has(.sidebar__input:checked) {
      overflow-x: hidden;
      grid-template-columns: auto 100vw 0;
    }
  }
}


.app__header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-auto-flow: column;
  align-items: center;
  margin: var(--op-space-small) var(--op-space-medium);
  background-color: var(--op-color-neutral-plus-max);
  box-shadow: var(--c12-card-shadow);

  @media (width > $breakpoint-small) {
    grid-template-columns: 1fr;
  }

  @media (width <=$breakpoint-medium) {
    margin: 0;
    box-shadow: var(--c12-card-shadow-bottom);
  }
}

.app__content {
  display: flex;
  flex-direction: column;
  gap: var(--op-space-large);
  padding: var(--op-space-medium);
  max-width: var(--op-breakpoint-large);

  @media (width <=$breakpoint-medium) {
    padding: var(--op-space-medium);
  }

  @media (width <=$breakpoint-small) {
    padding: var(--op-space-x-small);
  }
}

.wrapper--native {
  width: 100%;
  overflow-y: auto;

  &.wrapper--android {
    padding-bottom: var(--android-tabbar-height);
  }
}

.app-body:has(.wrapper--native) {
  min-height: 100dvh;
  height: 100dvh;
  .modal__header {
    display: none;
  }

  .page-header__title {
    display: none;
  }

  .page-header {
    border-bottom: none;
    padding: var(--op-space-small);
  }

  .search-bar {
    max-width: 100%;
  }
}

.header-space {
  @media(max-width: #{$breakpoint-small}) {
    margin-top: 70px;
  }
}

.more-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: var(--op-space-medium);
  background-color: var(--op-color-tertiary-minus-six);
  color: var(--op-color-tertiary-on-minus-six);
  padding-inline: var(--op-space-medium);
  padding-block: var(--op-space-small) calc(var(--op-space-scale-unit) * 10); // 110px

  height: 100dvh;
  width: 100%;
}

// dvh doesn't seem to work in android
.wrapper--android {
  .more-navigation {
    height: 100vh;
  }
}

.report-checklist-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  gap: var(--op-space-large);
}
