.payment-option {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.payment-option input:checked + .card {
  border-color: var(--op-color-primary-minus-three);
  background-color: var(--op-color-primary-plus-seven);
  color: var(--op-color-primary-on-plus-seven);
}
