@import 'color-scale';

@mixin primary-semantic-scale-dark-overrides {
  --op-color-primary-original: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 20%);
  --op-color-primary-base: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 35%);
  --op-color-primary-on-base: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 96%);
  --op-color-primary-on-minus-three: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 96%);
  --op-color-primary-on-minus-three-alt: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 88%);
  --op-color-primary-on-minus-four: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 95%);
  --op-color-primary-on-minus-four-alt: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 87%);
  --op-color-primary-on-minus-five: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 94%);
  --op-color-primary-on-minus-five-alt: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 86%);
  --op-color-primary-on-minus-six: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 92%);
  --op-color-primary-on-minus-six-alt: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 84%);
  --op-color-primary-original: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 50%);
}

@mixin neutral-semantic-scale-dark-overrides {
  --op-color-neutral-on-plus-max: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 90%);
  --op-color-neutral-on-plus-max-alt: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 85%);
  --op-color-neutral-on-plus-eight: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 84%);
  --op-color-neutral-plus-eight: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 4%);
  --op-color-neutral-on-minus-two-alt: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 100%);
  --op-color-neutral-on-minus-three-alt: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 100%);
  --op-color-neutral-on-minus-four-alt: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 0%);
}

@mixin custom-component-dark-colors {
  --curriculum-resource-devotion-color: hsl(0, 0%, 0%);
  --curriculum-resource-devotion-on-color: hsl(34, 65%, 46%);
  --curriculum-resource-devotion-on-color-alt: hsl(34, 65%, 56%);

  --curriculum-resource-ministry-color: hsl(0, 0%, 0%);
  --curriculum-resource-ministry-on-color: hsl(209, 70%, 46%);
  --curriculum-resource-ministry-on-color-alt: hsl(209, 70%, 56%);

  --curriculum-resource-business-color: hsl(0, 0%, 0%);
  --curriculum-resource-business-on-color: hsl(162, 70%, 40%);
  --curriculum-resource-business-on-color-alt: hsl(162, 70%, 50%);

  --curriculum-resource-business_ministry-color: hsl(0, 0%, 0%);
  --curriculum-resource-business_ministry-on-color: hsl(300, 36%, 40%);
  --curriculum-resource-business_ministry-on-color-alt: hsl(300, 36%, 50%);

  --sidebar-color: var(--op-color-tertiary-plus-seven);
  --sidebar-on-color: var(--op-color-tertiary-on-plus-seven);
  --sidebar-on-color-alt: var(--op-color-tertiary-on-plus-seven-alt);
  --sidebar-border-color: var(--op-color-tertiary-plus-four);

  --sidebar-identity-color: var(--op-color-tertiary-plus-eight);
  --sidebar-identity-on-color: var(--op-color-tertiary-on-plus-eight);
  --sidebar-identity-on-color-alt: var(--op-color-tertiary-on-plus-eight-alt);
  --sidebar-identity-border-color: var(--op-color-tertiary-plus-six);
  --sidebar-identity-hover-color: var(--op-color-tertiary-plus-eight);
  --sidebar-identity-hover-on-color: var(--op-color-tertiary-on-plus-eight);
  --sidebar-identity-hover-on-color-alt: var(--op-color-tertiary-on-plus-seven-alt);
  --sidebar-identity-hover-border-color: var(--op-color-primary-minus-two);

}

@mixin dark-border-colors {
  --op-color-border: var(--op-color-neutral-plus-two);
}

@mixin dark-background {
  --c12-gradient-background: linear-gradient(180deg, var(--op-color-neutral-plus-seven) 0%, var(--op-color-neutral-plus-eight) 100%);
}

@mixin dark-shadows {
  --c12-card-shadow: 0px 0px 0px 1px var(--op-color-border) inset;
  --c12-card-shadow-bottom: 0px 1px 1px var(--op-color-border);
}

@mixin all-dark-mode-tokens {
  @include dark-color-scale('secondary', 35, 62%, 69%);
  @include dark-color-scale('tertiary', 192, 50%, 14%);
  @include dark-color-scale('type-content', 248, 30%, 50%);
  @include dark-color-scale('type-curriculum', 248, 30%, 50%);
  @include dark-color-scale('prayer-request', 52, 58%, 31%);
  @include dark-color-scale('question', 248, 30%, 50%);
  @include dark-color-scale('material', 349, 29%, 43%);
  @include dark-color-scale('meeting', 164, 13%, 38%);
  @include dark-border-colors;
  @include custom-component-dark-colors;
  @include dark-background;
  @include dark-shadows;
  @include primary-semantic-scale-dark-overrides;
  @include neutral-semantic-scale-dark-overrides;
}


@media(prefers-color-scheme: dark) {
  :root:not([data-theme-mode='light']) {
    @include all-dark-mode-tokens;
    color-scheme: dark;
  }
}

:root[data-theme-mode='dark'] {
  @include all-dark-mode-tokens;
  color-scheme: dark;
}
