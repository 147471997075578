:root,
:host,
.sl-theme-light,
.sl-theme-dark {
  --sl-tooltip-font-family: var(--op-font-family-alt);
  --sl-tooltip-font-size: var(--op-font-small);
  --sl-tooltip-font-weight: var(--op-font-weight-normal);
  --sl-tooltip-line-height: var(--op-line-height-dense);
  --sl-tooltip-color: var(--op-color-tertiary-on-minus-eight);
  --sl-tooltip-background-color: var(--op-color-tertiary-minus-eight);
  
  sl-tooltip {
    --show-delay: .5s;
    --rm-min-tooltip-width: 15rem;
    --max-width: 20rem;

    .custom-icons {
      cursor: help;
      color: var(--op-color-neutral-on-plus-max-alt);
    }
     ul {
      margin-block: var(--op-space-2x-small) 0;
      margin-inline-start: var(--op-space-x-small);
      padding-inline-start: var(--op-space-x-small);
     }
  }

  sl-tooltip::part(body) {
    min-width: var(--rm-min-tooltip-width);
    padding: var(--op-space-small);
    box-shadow: var(--op-shadow-medium);
  }
  

  sl-tooltip.associated-materials-help {
    --show-delay: 0.2s;
  }

  .tooltip--large {
    --rm-min-tooltip-width: 25rem;
  }

  .tooltip--delay-short {
    --show-delay: 0.25s;
  }
}
