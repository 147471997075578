.discussion__labels {
  display: flex;
  gap: var(--op-space-2x-small);
  align-items: flex-start;

  @media (width <=#{$breakpoint-x-small}) {
    flex-direction: column;
  }
}

%content-label-global {
  box-shadow: inset var(--op-border-all) var(--op-color-neutral-plus-four);
    background-color: var(--op-color-neutral-plus-seven);
    color: var(--op-color-neutral-on-plus-seven);
    display: inline-block;
    padding: var(--op-space-2x-small) var(--op-space-x-small);
    border-radius: var(--op-radius-small);
    white-space: nowrap;
    font-family: var(--op-font-family);
    font-size: var(--op-font-2x-small);
    text-transform: uppercase;
    letter-spacing: var(--op-letter-spacing-label);
    font-weight: var(--op-font-weight-medium);
    line-height: var(--op-line-height-densest);
}

.discussion__label {
  @extend %content-label-global;

  &.discussion__label--prayer_request {
    box-shadow: inset var(--op-border-all) var(--op-color-prayer-request-plus-three);
    background-color: var(--op-color-prayer-request-plus-six);
    color: var(--op-color-prayer-request-on-plus-six);
  }

  &.discussion__label--question {
    box-shadow: inset var(--op-border-all) var(--op-color-question-plus-three);
    background-color: var(--op-color-question-plus-six);
    color: var(--op-color-question-on-plus-six);
  }

  &.discussion__label--material {
    box-shadow: inset var(--op-border-all) var(--op-color-material-plus-three);
    background-color: var(--op-color-material-plus-six);
    color: var(--op-color-material-on-plus-six);
  }

  &.discussion__label--meeting {
    box-shadow: inset var(--op-border-all) var(--op-color-meeting-plus-three);
    background-color: var(--op-color-meeting-plus-six);
    color: var(--op-color-meeting-on-plus-six);
  }

  &.discussion__label--meeting_topic {
    box-shadow: inset var(--op-border-all) var(--op-color-meeting-topic-plus-three);
    background-color: var(--op-color-meeting-topic-plus-six);
    color: var(--op-color-meeting-topic-on-plus-six);
  }
}


.label--danger {
  box-shadow: none;
  background-color: var(--op-color-alerts-danger-plus-five);
  color: var(--op-color-alerts-danger-on-plus-five);
}

.user-badge__label {
  @extend %content-label-global;

  &.chair-stage-1 {
    box-shadow: inset var(--op-border-all) var(--op-color-chair-stage-1-minus-four);
    background-color: var(--op-color-chair-stage-1-plus-seven);
    color: var(--op-color-chair-stage-1-minus-four);
  }

  &.chair-stage-2 {
    box-shadow: inset var(--op-border-all) var(--op-color-chair-stage-2-base);
    background-color: var(--op-color-chair-stage-2-plus-six);
    color: var(--op-color-chair-stage-2-base);
  }

  &.chair-stage-3 {
    box-shadow: inset var(--op-border-all) var(--op-color-chair-stage-3-base);
    background-color: var(--op-color-chair-stage-3-plus-six);
    color: var(--op-color-chair-stage-3-base);
  }

  &.chair-stage-4 {
    box-shadow: inset var(--op-border-all) var(--op-color-chair-stage-4-base);
    background-color: var(--op-color-chair-stage-4-plus-six);
    color: var(--op-color-chair-stage-4-base);
  }

  &.chair-stage-5 {
    box-shadow: inset var(--op-border-all) var(--op-color-chair-stage-5-base);
    background-color: var(--op-color-chair-stage-5-plus-six);
    color: var(--op-color-chair-stage-5-base);
  }

  &.chair-stage-assistant {
    box-shadow: inset var(--op-border-all) var(--op-color-material-plus-three);
    background-color: var(--op-color-material-plus-six);
    color: var(--op-color-material-on-plus-six);
  }

  &.chair-stage-principal {
    box-shadow: inset var(--op-border-all) var(--op-color-secondary-plus-three);
    background-color: var(--op-color-secondary-plus-six);
    color: var(--op-color-secondary-on-plus-six);
  }

  &.hq-staff {
    box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-three);
    background-color: var(--op-color-primary-plus-six);
    color: var(--op-color-primary-on-plus-six);
  }
}
