#policy-document {
  margin-block-start: var(--op-space-2x-large);

  h4 {
    text-align: center;

    &:first-of-type {
      margin-block-end: var(--op-space-large);
    }
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }

  sup {
    color: var(--op-color-neutral-on-plus-max-alt);
  }

  p {
    line-height: 1.2;
    font-size: var(--op-font-medium);
    margin-block-end: var(--op-space-small);
    text-align: justify;

    &:last-child {
      margin-block-end: var(--op-space-2x-large);
    }
  }

  ol,ul {
    padding-inline-start: 50px;
    ::marker {
      letter-spacing: 3px;
    }
    li {
      margin-block-end: var(--op-space-small);

      &:last-child {
        margin-block-end: var(--op-space-2x-large);
      }
    }
  }

  .policy--highlight {
    color: var(--color-status-danger-border);
    font-weight: bold;
  }
}
