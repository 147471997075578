:root {
  --modal-content-height: 800px;
}

.modal {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--op-z-index-dialog);
  overflow: hidden;
  /*Prevent Chrome on Windows from adding a focus outline. For details, see*/
  /*https://github.com/twbs/bootstrap/pull/10951.*/
  outline: 0;
  /*We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  See also https://github.com/twbs/bootstrap/issues/17695*/
}

.modal__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--op-z-index-dialog-backdrop);
  background: var(--op-color-neutral-minus-max);
  visibility: hidden;
  opacity: 0;
  transition: all var(--modal-transition-speed);
}

.modal__content { 
  max-height: var(--modal-content-height);
  position: relative;
  width: var(--_op-modal-width);
  background: var(--op-color-neutral-plus-max);
  border-radius: 0;
  z-index: var(--op-z-index-dialog-content);
  transform: scale(0.7);
  opacity: 0;
  overflow: hidden;
  transition: all var(--modal-transition-speed);
}

.modal__content--padded {
  padding: var(--op-space-large);
}

.modal--active {
  display: flex;
  visibility: visible;

  .modal__content {
    transform: scale(1);
    opacity: 1;
  }

  .modal__backdrop {
    visibility: visible;
    opacity: 0.5;
  }
}

.modal__content-wrapper {
  display: flex;
  flex-direction: column;
  max-height: var(--modal-content-height);
  overflow-y: auto;

  &.wrapper--android {
    overflow-y: unset;
  }
}

.modal--lg {
  --_op-modal-width: 900px;
}

.modal--xl {
  --_op-modal-width: 80vw;
  --modal-height-subtraction: 200px;

  .modal__content {
    max-height: calc(100vh - var(--modal-height-subtraction));
  }

  .modal__content-wrapper {
    max-height: calc(100vh - var(--modal-height-subtraction));
  }
}

.modal--fullscreen {
  .modal__content {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;

    border-radius: 0;
    --modal-transition-speed: 0;
  }

  .modal__content-wrapper {
    height: 100vh;
    max-height: 100vh;
  }
}

.modal__body {
  overflow-y: auto;
  padding: var(--op-space-large);

  @media (max-width: $breakpoint-small) {
    max-height: calc(100vh - 200px);
  }

}

.wrapper--android {
  .modal__body {
    overflow-y: unset;
  }
}

.modal__header {
  border-bottom: solid 1px var(--op-color-border);
  padding: var(--op-space-small) var(--op-space-4x-large) var(--op-space-small) var(--op-space-large);
  z-index: 1;
}

.modal__footer {
  display: flex;
  justify-content: flex-end;
  border-top: solid 1px var(--op-color-border);
  padding: var(--op-space-small) var(--op-space-small);
  z-index: 1;
}

.modal__close {
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 13;
}

// This piece is to keep turbo from animating and then hiding our modals, creating a bouncing effect
.modal:not(.modal--active) {
  visibility: hidden;
  opacity: 0;
}
