@media (max-width: #{$breakpoint-small}) {
  .mobile-full-width {
    width: 100%;
  }
}

.width-md {
  max-width: 50rem;
}

.full-height {
  height: 100%;
}

// Grid Properties

.grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: var(--op-space-large);
}

.grid--two-column {
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: #{$breakpoint-medium}) {
    grid-template-columns: 1fr;
  }
}

.grid--two-thirds-column {
  grid-template-columns: 2fr 1fr;

  @media (max-width: #{$breakpoint-medium}) {
    grid-template-columns: 1fr;
  }
}

.grid--three-column {
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: #{$breakpoint-medium}) {
    grid-template-columns: 1fr;
  }
}

// Flex Properties

@media (max-width: #{$breakpoint-small}) {
  .mobile-flex-col {
    flex-direction: column;
  }
}

@media (max-width: #{$breakpoint-small}) {
  .mobile-flex-row {
    flex-direction: row;
  }
}

.flex-1 {
  flex: 1;
}

.gap-xs {
  gap: var(--op-space-x-small);
}

.gap-sm {
  gap: var(--op-space-small);
}

.gap-md {
  gap: var(--op-space-small);
}

.gap-lg {
  gap: var(--op-space-large);
}

.gap-xl {
  gap: var(--op-space-2x-large);
}

.flex-gap {
  display: inline-flex;
  gap: var(--op-space-large);
  width: 100%;

  @media (max-width: #{$breakpoint-small}) {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
}

.flex-gap > * {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

// Flex basis

.flex-half {
  flex: 1 1 clamp(150px, unquote("calc(50% - var(--gap))"), 100%);
}

.flex-third {
  flex: 1 1 clamp(150px, unquote("calc(33% - var(--gap))"), 100%);
}

.flex-two-thirds {
  flex: 1 1 clamp(150px, unquote("calc(67% - var(--gap))"), 100%);
}

.flex-fourth {
  flex: 1 1 clamp(150px, unquote("calc(25% - var(--gap))"), 100%);
}

// Text Alignment

.vert-align-top {
  vertical-align: top;
}

// Text Color
// TODO: Optics doesn't have a pattern for a color that is not on a background color.
.text-success {
  color: var(--op-color-alerts-notice-base);
}

.text-danger {
  color: var(--op-color-alerts-danger-base);
}

.text-danger-dark {
  color: var(--op-color-alerts-danger-base);
}

.text-warning {
  color: var(--op-color-alerts-warning-base);
}

.text-light {
  color: var(--op-color-on-background-alt);
}

// Font size

.font-xl {
  font-size: var(--op-font-2x-large);
}

.font-md {
  font-size: var(--op-font-medium);
}

.font-sm {
  font-size: var(--op-font-small);
}

.font-xs {
  font-size: var(--op-font-x-small);
}

// Font weight

.text-bold {
  font-weight: 500;
}

// Box Margin

.margin-xxl {
  margin: var(--op-space-4x-large);
}

.margin-xl {
  margin: var(--op-space-2x-large);
}

// Vertical Margin

.margin-y-xxl {
  margin-top: var(--op-space-4x-large);
  margin-bottom: var(--op-space-4x-large);
}

.margin-y-xl {
  margin-top: var(--op-space-2x-large);
  margin-bottom: var(--op-space-2x-large);
}

// Horizontal Margin

.margin-x-xxl {
  margin-right: var(--op-space-4x-large);
  margin-left: var(--op-space-4x-large);
}

.margin-x-xl {
  margin-right: var(--op-space-2x-large);
  margin-left:var(--op-space-2x-large);
}

// Top Margin

.margin-top-xxl {
  margin-top: var(--op-space-4x-large);
}

.margin-top-xl {
  margin-top: var(--op-space-2x-large);
}

// Bottom Margin

.margin-bottom-xxl {
  margin-bottom: var(--op-space-4x-large);
}

.margin-bottom-xl {
  margin-bottom: var(--op-space-2x-large);
}

// Right Margin

.margin-right-xxl {
  margin-right: var(--op-space-4x-large);
}

.margin-right-xl {
  margin-right: var(--op-space-2x-large);
}

// Left Margin

.margin-left-xl {
  margin-left: var(--op-space-2x-large);
}

.padding-lg {
  padding: var(--op-space-large);
}

.padding-y-lg {
  padding: var(--op-space-large) 0;
}

.is-hidden {
  display: none !important;
}

.hide-xs {
  @media screen and (max-width: #{$breakpoint-x-small}) {
    display: none;
  }
}

.hide-sm {
  @media screen and (max-width: #{$breakpoint-small}) {
    display: none;
  }
}

.hide-md {
  @media screen and (max-width: #{$breakpoint-medium}) {
    display: none;
  }
}

.hide-lg {
  @media screen and (max-width: #{$breakpoint-large}) {
    display: none;
  }
}

.hide-xl {
  @media screen and (max-width: #{$breakpoint-x-large}) {
    display: none;
  }
}

// Text Colors
.subheader-gray {
  color: var(--op-color-on-background-alt);
}

.subheader-gray-light {
  color: var(---op-color-on-background-alt);
}

.flip-h {
  transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
}

.standout-content {
  background: var(--op-color-secondary-plus-four);
  padding: var(--op-space-x-small);
}

.nowrap {
  white-space: nowrap;
}

@media screen {
  .no-screen {
    display: none !important;
  }
}
