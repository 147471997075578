.progress-bar_wrap {
  width: var(--width-scale-md);
  height: var(--op-space-x-small);
  background: var(---op-color-neutral-plus-max);
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  border-radius: var(--op-radius-large)
}

.progress-bar_text {
  display: none;
  color: var(--op-color-neutral-on-plus-max-alt);
  margin-left: var(--op-space-x-small);
  transition: .2s;
}

.card--themed {
  .progress-bar_text {
    color: var(--op-color-primary-on-minus-five-alt);
  }
}

.progress-bar_text.active {
  display: inline-block;
}

.flex-row .progress-bar_item {
  display: inline-block;
  height: var(--op-space-x-small);
  width: 32.5%;
  margin-right: .8%;
  margin-bottom: var(--height-scale-base);
  border-radius: var(--op-radius-large);
  transition: background-color .2s, visisility .1s;
}

.flex-row .progress-bar_weak-item.active {
  background-color: var(--op-color-alerts-danger-plus-six);
}

.flex-row .progress-bar_good-item.active {
  background-color: var(--op-color-alerts-warning-plus-six);
}

.flex-row .progress-bar_strong-item.active {
  background-color: var(--op-color-alerts-notice-plus-six);
}
