// TODO: Refactor dropdown to use Optics classes
.dropdown__menu {
  height: 0;
  overflow: hidden;
  width: 0;
}

.dropdown__menu--open {
  height: auto;
  position: absolute;
  background-color: var(--op-color-neutral-plus-max);
  padding: var(--op-space-small) var(--op-space-small);
  right: 0;
  width: auto;
  min-width: 120px;
  box-shadow: var(--op-shadow-x-large);
  z-index: 20;
}

.dropdown__menu-item {
  padding: var(--op-space-small) 0;
  font-size: var(--op-font-small);
  cursor: pointer;
}

input[type="submit"].dropdown__menu-item {
  appearance: none;
  background: none;
  border: none;
}

%menu-global {
  font-family: var(--op-font-family);
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  box-shadow: var(--op-border-all) var(--op-color-border), var(--op-shadow-large);
  border-radius: 0;
  max-width: 25rem;
  z-index: var(--op-z-index-dropdown);

  .menu__section {
    padding: .8rem 0;

    &:not(:first-child) {
      border-top: solid rgb(228, 229, 231) 0px 0px 0px 1px;
    }
  }

  .menu-item {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: var(--op-font-small);
    padding: var(--op-space-2x-small) var(--op-space-medium);
    font-weight: var(--op-font-weight-normal);
    cursor: pointer;
    color: inherit;

    .menu-item__text {
      font-weight: inherit;
      color: inherit;
      flex-grow: 1;
      padding: 0;
    }

    &:hover {
      background-color: var(--op-color-background);
      color: var(--op-color-on-background);
    }
  }
}

.menu {
  @extend %menu-global;
}

// Dropdown

.dropdown-menu {
  position: relative;
  border-radius: 0;

  .menu {
    position: absolute;
    top: calc(100% + 2px);
    width: max-content;

    &.menu--left {
      left: 0;
    }

    &.menu--right {
      right: 0;
    }
  }
}
