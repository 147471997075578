// Note: This was pulled from Optics v1.1.0 in order to remove the variable font axis from Material Fonts.
// The downside is that we cannot use Font Weight, Emphasis, or Filled, but we can still use font size.
// The upside is that the payload went from 3.4mb to 265kb which removes a majority of the unstyled flash that comes with the font.

// Optics v2.1.0 have implemented this functionality so this can be removed when updating to that version of Optics.

// Look up icons at: https://fonts.google.com/icons?icon.set=Material+Symbols
// Instead of hosting the font files locally which requires the compiler to handle them, we pull the icons
// from Googles CDN like we do for our other fonts
// https://developers.google.com/fonts/docs/material_symbols#variable_font_with_google_fonts

@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:wght@100..700&display=block';

.material-symbols-outlined,
.material-symbols {
  --op-mso-fill: 0;
  --op-mso-weight: var(--op-font-weight-normal);
  --op-mso-grade: 0;
  --op-mso-optical-sizing: 20;

  font-size: var(--op-font-medium);
  font-variation-settings:
    'FILL' var(--op-mso-fill),
    'wght' var(--op-mso-weight),
    'GRAD' var(--op-mso-grade),
    'opsz' var(--op-mso-optical-sizing);
  line-height: var(--op-line-height-densest);
  vertical-align: middle;

  width: var(--op-font-medium);
  height: var(--op-font-medium);
}

// Fill
.icon--outlined {
  --op-mso-fill: 0;
}

.icon--filled {
  --op-mso-fill: 1;
}

// Weight
.icon--weight-light {
  --op-mso-weight: var(--op-font-weight-light);
}

.icon--weight-normal {
  --op-mso-weight: var(--op-font-weight-normal);
}

.icon--weight-semi-bold {
  --op-mso-weight: var(--op-font-weight-semi-bold);
}

.icon--weight-bold {
  --op-mso-weight: var(--op-font-weight-bold);
}

// Emphasis
.icon--low-emphasis {
  --op-mso-grade: -20;
}

.icon--normal-emphasis {
  --op-mso-grade: 0;
}

.icon--high-emphasis {
  --op-mso-grade: 200;
}

// Size
.icon--medium {
  font-size: var(--op-font-medium);
  width: var(--op-font-medium);
  height: var(--op-font-medium);

  --op-mso-optical-sizing: 20;
}

.icon--large {
  font-size: var(--op-font-large);
  width: var(--op-font-large);
  height: var(--op-font-large);

  --op-mso-optical-sizing: 40;
}

.icon--x-large {
  font-size: var(--op-font-2x-large);
  width: var(--op-font-2x-large);
  height: var(--op-font-2x-large);

  --op-mso-optical-sizing: 48;
}

.custom-icons {
  display: inline-block;
  width: var(--op-font-2x-large);
  height: var(--op-font-2x-large);

  &.icon--medium {
    font-size: var(--op-font-small);

    --op-mso-optical-sizing: 20;
  }

  &.icon--large {
    width: var(--op-font-large);
    height: var(--op-font-large);
  }

  &.icon--x-large {
    width: var(--op-font-3x-large);
    height: var(--op-font-3x-large);
  }
}

.custom-icons svg {
  width: 100%;
  height: 100%;
}

.icon-link {
  line-height: 0;
}

// The following is app specific

:root  {
  --_icon-width-medium: calc(var(--op-size-unit) * 4); // 16px
  --_icon-width-large: calc(var(--op-size-unit) * 5); // 20px
  --_icon-width-x-large: calc(var(--op-size-unit) * 8); // 32px
  --_icon-width-2x-large: calc(var(--op-size-unit) * 10); //40px
}

%custom-icons-global {
  --_icon-stroke-width-small: 2px;
  --_icon-stroke-width-medium: 2px;
  --_icon-stroke-width-large: 1.5px;
  --_icon-stroke-width-x-large: 1px;

  // Private API (don't touch these)
  --__icon-stroke-width: var(--_icon-stroke-width-medium);
  --__icon-width: var(--_icon-width-medium);

  display: inline-block;
  width: var(--__icon-width);
  min-width: var(--__icon-width);
  height: var(--__icon-width);

  path {
    stroke: currentColor;
    stroke-width: var(--__icon-stroke-width);
  }

  &.icon--medium {
    --__icon-width: var(--_icon-width-medium);
    --__icon-stroke-width: var(--_icon-stroke-width-small);
  }

  &.icon--large {
    --__icon-width: var(--_icon-width-large);
    --__icon-stroke-width: var(--_icon-stroke-width-medium);
  }

  &.icon--x-large {
    --__icon-width: var(--_icon-width-x-large);
    --__icon-stroke-width: var(--_icon-stroke-width-large);
  }

  &.icon--2x-large {
    --__icon-width: var(--_icon-width-2x-large);
    --__icon-stroke-width: var(--_icon-stroke-width-x-large);
  }
}

.custom-icons {
  @extend %custom-icons-global;
}

.custom-icons--gold {
  path {
    stroke: var(--op-color-secondary-base);
  }
}

.material-symbols-outlined,
.material-symbols {
  --op-mso-fill: 0;
  --op-mso-weight: var(--op-font-weight-normal);
  --op-mso-optical-sizing: 24;

  font-size: var(--op-font-large);
  font-weight: var(--op-mso-weight);
}
