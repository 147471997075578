%btn-global {
  --_op-btn-font-medium: var(--op-font-x-small);
  line-height: var(--op-line-height-densest);
  font-weight: var(--op-font-weight-medium);
  border-radius: 0;
  text-transform: uppercase;
  font-family: var(--op-font-family);
}

button {
  margin: 0;
  padding: 0;
  line-height: var(--op-line-height-densest);

  cursor: pointer;

  border: 0;
  outline: 0;
  background: none;
}

.btn {
  @extend %btn-global;

  &:active {
    opacity: .7;
  }

  &.btn--no-border {
    background-color: transparent;
    box-shadow: none;
    color: var(--op-color-on-background);
    cursor: pointer;

    &.text-danger {
      color: var(--op-color-alerts-danger-base); // TODO: No Optics pattern for color without a background-color: ;
    }

    // Hover State
    &:hover {
      box-shadow: inset var(--op-border-all) var(--op-color-primary-base);
    }
  }

  // Custom button modifier, not a part of optics
  &.btn--outline {
    background-color: var(--op-color-neutral-plus-max);
    color: var(--op-color-neutral-on-plus-max);
    box-shadow: inset var(--op-border-all) var(--op-color-neutral-plus-two);

    &:hover {
      background-color: var(--op-color-primary-plus-eight);
      color: var(--op-color-primary-on-plus-eight);
      box-shadow: inset var(--op-border-all) var(--op-color-neutral-plus-three);
    }
  }


  // Disabled Modifier
  &.btn--disabled {
    background: var(--op-color-neutral-plus-max);
    color: var(--op-color-neutral-on-plus-max);
    box-shadow: inset var(--op-border-all) var(--op-color-neutral-plus-five);

    cursor: not-allowed;

    &:hover {
      background-color: var(--op-color-neutral-plus-max);
      color: var(--op-color-neutral-plus-max);
      box-shadow: inset var(--op-border-all) var(--op-color-neutral-plus-six);
    }
  }

  // Hover State
  &:hover {
    box-shadow: inset var(--op-border-all) var(--op-color-primary-base);
  }

  // Define Custom Icon Sizes for btn
  &.btn--small {
    svg {
      width: var(--_icon-width-medium);
      height: var(--_icon-width-medium);
    }
  }

  &.btn--medium {
    svg {
      width: var(--_icon-width-large);
      height: var(--_icon-width-large);
    }
  }

  &.btn--large {
    svg {
      width: var(--_icon-width-x-large);
      height: var(--_icon-width-x-large);
    }
  }
}

.btn-primary {
  @extend %btn-global;
  background-color: var(--op-color-primary-minus-three);
  color: var(--op-color-primary-on-minus-three);
  box-shadow: inset var(--op-border-all) var(--op-color-primary-minus-three);

  &:hover {
    background: var(--op-color-primary-minus-five);
    color: var(--op-color-primary-on-minus-five);
    box-shadow: inset var(--op-border-all) var(--op-color-primary-minus-five);
  }

  // Custom button modifier, not a part of optics
  &.btn--outline {
    background-color: var(--op-color-primary-plus-max);
    color: var(--op-color-primary-on-plus-max-alt);
    box-shadow: inset var(--op-border-all) var(--op-color-primary-minus-three);

    &:hover {
      background-color: var(--op-color-primary-plus-eight);
      color: var(--op-color-primary-on-plus-eight);
      box-shadow: inset var(--op-border-all) var(--op-color-primary-plus-three);
    }
  }
}

.btn-secondary {
  @extend %btn-global;
  background-color: var(--op-color-neutral-plus-max);
  color: var(--op-color-neutral-on-plus-max);
  box-shadow: inset var(--op-border-all) var(--op-color-neutral-minus-seven);

  &:hover {
    background-color: var(--op-color-neutral-plus-seven);
    color: var(--op-color-neutral-on-plus-seven);
    box-shadow: inset var(--op-border-all) var(--op-color-neutral-minus-two);
  }

  // Focus State
  &:focus-visible {
    background-color: var(--op-color-neutral-plus-eight);
    box-shadow: var(--op-input-focus-primary);
    color: var(--op-color-neutral-on-plus-eight);
  }
}

.btn--active {
  background: var(--op-color-primary-minus-five);
  color: var(--op-color-primary-on-minus-five);

  &:hover:not(:disabled) {
    background: var(--op-color-primary-minus-five);
    color: var(--op-color-primary-on-minus-four);
  }
}

.btn-destructive {
  @extend %btn-global;
  background-color: var(--op-color-alerts-danger-base);
  color: var(--op-color-alerts-danger-on-base);
  box-shadow: inset var(--op-border-all) var(--op-color-alerts-danger-minus-two);

  &:hover {
    background-color: var(--op-color-alerts-danger-minus-two);
    box-shadow: inset var(--op-border-all) var(--op-color-alerts-danger-minus-two);
    color: var(--op-color-alerts-danger-on-minus-two);
  }

  &.btn--outline {
    background-color: var(--op-color-alerts-danger-plus-max);
    color: var(--op-color-alerts-danger-on-plus-max);
    box-shadow: inset var(--op-border-all) var(--op-color-alerts-danger-minus-two);
  }

  &:hover {
    background-color: var(--op-color-alerts-danger-plus-seven);
    color: var(--op-color-alerts-danger-on-plus-seven);
    box-shadow: inset var(--op-border-all) var(--op-color-alerts-danger-minus-three);
  }
}

.floating-action-button {
  position: absolute;
  right: var(--op-space-2x-large);
  bottom: var(--op-space-2x-large);
}

.btn--lowercase {
  text-transform: none;
}
