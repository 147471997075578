// Third party Vendors
@import 'modern-css-reset/dist/reset';

// Tokens
@import '@rolemodel/optics/dist/scss/core/tokens/index';

// Base styles and utilities
@import '@rolemodel/optics/dist/scss/core/base';
@import '@rolemodel/optics/dist/scss/core/layout';
@import '@rolemodel/optics/dist/scss/core/utilities';

//Theme
@import 'core/themes/c12-theme-core';
@import 'core/themes/c12-theme-core-dark';

// Optics Components
@import '@rolemodel/optics/dist/scss/components/accordion';
@import '@rolemodel/optics/dist/scss/components/avatar';
@import '@rolemodel/optics/dist/scss/components/badge';
@import '@rolemodel/optics/dist/scss/components/button';
@import '@rolemodel/optics/dist/scss/components/card';
@import '@rolemodel/optics/dist/scss/components/divider';
@import '@rolemodel/optics/dist/scss/components/form';
@import '@rolemodel/optics/dist/scss/components/pagination';
@import '@rolemodel/optics/dist/scss/components/sidebar';

// Up Here since we are changing the icon CDN import
@import 'components/optics-overrides/icon';

// General Styles
@import 'fonts';
@import 'variables';
@import 'base';
@import 'containers';
@import 'print';
@import 'styleguide';
@import 'utilities';

// Optics Component Customization
@import 'components/optics-overrides/accordion';
@import 'components/optics-overrides/avatar';
@import 'components/optics-overrides/badge';
@import 'components/optics-overrides/button';
@import 'components/optics-overrides/card';
@import 'components/optics-overrides/form';
@import 'components/optics-overrides/pagination';
@import 'components/optics-overrides/sidebar';
@import 'core/layout';

// components
@import 'components/banner';
@import 'components/breadcrumbs';
@import 'components/category';
@import 'components/chart';
@import 'components/curriculum';
@import 'components/dashboard';
@import 'components/discussion';
@import 'components/dropdown';
@import 'components/flash';
@import 'components/header';
@import 'components/identity';
@import 'components/label';
@import 'components/link_resources';
@import 'components/login';
@import 'components/mentions';
@import 'components/modal';
@import 'components/notifications';
@import 'components/panel';
@import 'components/password_complexity';
@import 'components/payment';
@import 'components/pdf';
@import 'components/policy';
@import 'components/pill';
@import 'components/report_nav';
@import 'components/resource_grid';
@import 'components/search';
@import 'components/snapshot';
@import 'components/status';
@import 'components/strength_meter';
@import 'components/swatch';
@import 'components/table';
@import 'components/tile';
@import 'components/toggle';
@import 'components/tom-select-icons';
@import 'components/video';
@import 'components/wheel';

@import 'native_bridge';

// Vendor Customization
@import 'flatpickr/dist/flatpickr.css';
@import 'flatpickr/dist/plugins/monthSelect/style.css';
@import "trix/dist/trix";
@import 'vendor/actiontext';
@import 'vendor/custom-tom-select';

@import '@shoelace-style/shoelace/dist/themes/light.css';
@import '@shoelace-style/shoelace/dist/themes/dark.css';
@import 'shoelace/sl_tooltip';
