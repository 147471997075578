// taken from https://stackoverflow.com/a/34310815/2100919

:root {
  --_op-avatar-size-medium: calc(10 * var(--op-size-unit)); // 40px
}

%avatar-global {
  --_op-avatar-size-x-large: calc(18 * var(--op-size-unit)); // 72px
  --_op-avatar-size-2x-large: calc(22 * var(--op-size-unit)); // 88px
  --_op-avatar-size-3x-large: calc(30 * var(--op-size-unit)); // 120px
  
  --_op-avatar-size-large: calc(14 * var(--op-size-unit)); // 56px
  --_op-avatar-inner-border-color: var(--op-color-secondary-base);
  --_op-avatar-inner-border-width: var(--op-border-width);
  --_op-avatar-outer-border-width: 0;
  --avatar-z-index-letters: 1;
  --avatar-z-index-image: 2;
  position: relative;
  
  // Size Modifiers
  &.avatar--x-large {
    --__op-avatar-size: var(--_op-avatar-size-x-large);
  }

  &.avatar--2x-large {
    --__op-avatar-size: var(--_op-avatar-size-2x-large);
  }

  &.avatar--3x-large {
    --__op-avatar-size: var(--_op-avatar-size-3x-large);
  }
}

.avatar {
  @extend %avatar-global;
  background-color: var(--op-color-secondary-plus-four);
  color: var(--op-color-secondary-on-plus-four);
  display: grid;
  overflow: hidden;

  img {
    z-index: var(--avatar-z-index-image);
  }
  &:has(img) {
    --_op-avatar-inner-border-color: var(--op-color-neutral-base);
    background-color: var(--op-color-neutral-plus-six);
    color: var(--op-color-neutral-on-plus-six);
  }
}

.avatar--neutral .avatar {
  --_op-avatar-inner-border-color: var(--op-color-neutral-plus-four);
  background-color: var(--op-color-neutral-plus-six);
  color: var(--op-color-neutral-on-plus-six);
}

[data-letters]::before {
  grid-area: 1 / -1 / 1 / -1;
  content: attr(data-letters);
  display: flex;
  justify-content: center;
  font-family: var(--op-font-family);
  font-weight: var(--op-font-weight-medium);
  align-items: center;
  font-size: var(--op-font-x-small);
  padding: 0;
  z-index: var(--avatar-z-index-letters);
}

.avatar.avatar--small[data-letters]::before {
  font-size: var(--op-font-x-small);
}


// Generate random colors for avatars in avatar-group
$colors: primary, secondary, type-content, type-curriculum, prayer-request, question, material;

@mixin color-repeat($colors) {
  $total-colors: length($colors);

  @for $i from 1 through $total-colors {
    &:nth-child(#{$total-colors}n + #{$i}) {
      $color: nth($colors, $i);
      --_op-avatar-inner-border-color: var(--op-color-#{$color}-base);
      background-color: var(--op-color-#{$color}-plus-four);
      color: var(--op-color-#{$color}-on-plus-four);
      box-shadow: inset var(--op-border-all) var(--_op-avatar-inner-border-color),
      0px 0px var(--c12-shadow-blur-small) 0px var(--op-color-neutral-base-alpha-40);
    }
  }
}

.avatar-group {
  --avatar-group-transition: all 0.2s cubic-bezier(0.50, 0.080, 0.60, 0.50);
  display: flex;
  margin-left: 2em;
  .avatar {
    margin-left: calc((var(--_op-avatar-size-medium) / 2) * -1);
    transition: var(--avatar-group-transition);
    /* Overlap by 50% */
    @include color-repeat($colors);
    &:last-child {
      --_op-avatar-inner-border-color: var(--op-color-neutral-base);
      background-color: var(--op-color-neutral-plus-five);
      color: var(--op-color-neutral-on-plus-five);
      box-shadow: inset var(--op-border-all) var(--_op-avatar-inner-border-color), 0px 0px var(--c12-shadow-blur-small) 0px var(--op-color-neutral-base-alpha-40);
    }
  }
  &:hover {
    .avatar {
      margin-left: calc((var(--_op-avatar-size-medium) / 5) * -1);
    }
  }
}
