@import 'color-scale';

// color-varieties
@mixin color-varieties {

  --op-color-white: #FCFCFC;
  --op-color-black: #010C0F;

  // Color Primary Brand
  --op-color-primary-h: 191;
  --op-color-primary-s: 33%;
  --op-color-primary-l: 32%;

  // Color Neutral Brand
  --op-color-neutral-h: 215;
  --op-color-neutral-s: 5%;
  --op-color-neutral-l: 79%;

  // Color Alerts Notice
  --op-color-alerts-notice-h: 141;
  --op-color-alerts-notice-s: 62%;
  --op-color-alerts-notice-l: 54%;

  // Color Alerts Info
  --op-color-alerts-info-h: 192;
  --op-color-alerts-info-s: 33%;
  --op-color-alerts-info-l: 30%;

  // Color Alerts warning
  --op-color-alerts-warning-h: 47;
  --op-color-alerts-warning-s: 97%;
  --op-color-alerts-warning-l: 55%;

  // Color Alerts danger
  --op-color-alerts-danger-h: 355;
  --op-color-alerts-danger-s: 92%;
  --op-color-alerts-danger-l: 58%;
}

@mixin primary-semantic-scale-overrides {
  --op-color-primary-base: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 28%);
  --op-color-primary-on-base: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 96%);
  --op-color-primary-on-minus-three: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 96%);
  --op-color-primary-on-minus-three-alt: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 88%);
  --op-color-primary-on-minus-four: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 95%);
  --op-color-primary-on-minus-four-alt: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 87%);
  --op-color-primary-on-minus-five: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 94%);
  --op-color-primary-on-minus-five-alt: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 86%);
  --op-color-primary-on-minus-six: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 92%);
  --op-color-primary-on-minus-six-alt: hsl(var(--op-color-primary-h) var(--op-color-primary-s) 84%);
}

@mixin neutral-semantic-scale-overrides {
  --op-color-neutral-on-plus-max-alt: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 50%);
  --op-color-neutral-on-plus-one-alt: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 100%);
  --op-color-neutral-on-base: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 100%);
}

@mixin tertiary-semantic-scale-overrides {
  --op-color-tertiary-minus-six: hsl(var(--op-color-tertiary-h) var(--op-color-tertiary-s) 14%);
  --op-color-tertiary-on-minus-six: hsl(var(--op-color-tertiary-h) var(--op-color-tertiary-s) 96%);
  --op-color-tertiary-on-minus-six-alt: hsl(var(--op-color-tertiary-h) var(--op-color-tertiary-s) 88%);
  --op-color-tertiary-on-minus-seven: hsl(var(--op-color-tertiary-h) var(--op-color-tertiary-s) 96%);
}

@mixin alerts-notice-semantic-scale-overrides {
  --op-color-alerts-notice-on-base: hsl(var(--op-color-alerts-notice-h) var(--op-color-alerts-notice-s) 96%);
}

@mixin border-colors {
  --op-color-border: var(--op-color-neutral-plus-four);
}

@mixin custom-component-colors {
  --curriculum-resource-devotion-color: hsl(0, 0%, 97%);
  --curriculum-resource-devotion-on-color: hsl(34, 65%, 37%);
  --curriculum-resource-devotion-on-color-alt: hsl(34, 65%, 26%);

  --curriculum-resource-ministry-color: hsl(0, 0%, 97%);
  --curriculum-resource-ministry-on-color: hsl(209, 80%, 30%);
  --curriculum-resource-ministry-on-color-alt: hsl(209, 80%, 20%);

  --curriculum-resource-business-color: hsl(0, 0%, 97%);
  --curriculum-resource-business-on-color: hsl(162, 80%, 24%);
  --curriculum-resource-business-on-color-alt: hsl(162, 80%, 20%);

  --curriculum-resource-business_ministry-color: hsl(0, 0%, 97%);
  --curriculum-resource-business_ministry-on-color: hsl(300, 56%, 24%);
  --curriculum-resource-business_ministry-on-color-alt: hsl(300, 56%, 14%);

  // Redfine Sidebar Colors. The default optics scale is far too saturated to use in dark mode.
  --sidebar-color: var(--op-color-tertiary-minus-six);
  --sidebar-on-color: var(--op-color-tertiary-on-minus-six);
  --sidebar-on-color-alt: var(--op-color-tertiary-on-minus-six-alt);
  --sidebar-border-color: var(--op-color-tertiary-minus-four);

  --sidebar-identity-color: var(--op-color-tertiary-minus-seven);
  --sidebar-identity-on-color: var(--op-color-tertiary-on-minus-seven);
  --sidebar-identity-on-color-alt: var(--op-color-tertiary-on-minus-seven-alt);
  --sidebar-identity-border-color: var(--op-color-primary-plus-one);
  --sidebar-identity-hover-color: var(--op-color-tertiary-minus-eight);
  --sidebar-identity-hover-on-color: var(--op-color-tertiary-on-minus-eight);
  --sidebar-identity-hover-on-color-alt: var(--op-color-tertiary-on-minus-seven-alt);
  --sidebar-identity-hover-border-color: var(--op-color-primary-plus-two);
}

@mixin background {
  --c12-gradient-background: linear-gradient(180deg, var(--op-color-neutral-plus-six) 0%, var(--op-color-neutral-plus-seven) 100%);
}

@mixin shadows {
  --c12-card-shadow-inset-color: var(--op-color-border);
  --c12-card-shadow:
    0px 0px 0px 1px var(--c12-card-shadow-inset-color) inset,
    0px 4px 2px 0px hsla(218, 14%, 89%, 0.1),
    0px 4px 4px 0px hsla(218, 14%, 89%, 0.12),
    0px 2px 2px 0px hsla(218, 14%, 89%, 0.14),
    0px 2px 2px 0px hsla(0, 0%, 80%, 0.25);
  --c12-card-shadow-bottom:
      0px 1px 1px var(--c12-card-shadow-inset-color),
    0px 4px 2px 0px hsla(218, 14%, 89%, 0.1),
    0px 4px 4px 0px hsla(218, 14%, 89%, 0.12),
    0px 2px 2px 0px hsla(218, 14%, 89%, 0.14),
    0px 2px 2px 0px hsla(0, 0%, 80%, 0.25);
  --c12-shadow-blur-small: 3px;
}

@mixin opacity {
  --op-color-neutral-base-alpha-40: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 40% / 40%);
  --op-color-neutral-base-alpha-0: hsl(var(--op-color-neutral-h) var(--op-color-neutral-s) 40% / 0%);
}

@mixin fonts {
  --op-font-family: "Gotham", sans-serif;
  --op-font-family-alt: "Lato", sans-serif;
  --c12-letter-spacing-avatar: 0.1em;
}

@mixin transitions {
  //Transition Speeds
  --c12-transition-fast: all 150ms ease-in-out;
  --c12-transition-slow: all 250ms ease-in-out;
}

:root {
  @include color-varieties;
  @include color-scale('secondary', 35, 62%, 69%);
  @include color-scale('tertiary', 192, 100%, 14%);
  @include color-scale('type-content', 248, 30%, 50%);
  @include color-scale('type-curriculum', 36, 24%, 50%);
  @include color-scale('prayer-request', 52, 58%, 31%);
  @include color-scale('question', 248, 30%, 50%);
  @include color-scale('material', 349, 29%, 43%);
  @include color-scale('meeting', 164, 13%, 38%);
  @include color-scale('meeting-topic', 45, 30%, 50%);
  @include color-scale('chair-stage-1', 60, 100%, 25%);
  @include color-scale('chair-stage-2', 98, 47%, 36%);
  @include color-scale('chair-stage-3', 166, 46%, 43%);
  @include color-scale('chair-stage-4', 213, 53%, 55%);
  @include color-scale('chair-stage-5', 256, 60%, 36%);

  @include primary-semantic-scale-overrides;
  @include neutral-semantic-scale-overrides;
  @include alerts-notice-semantic-scale-overrides;
  @include tertiary-semantic-scale-overrides;
  @include custom-component-colors;
  @include border-colors;
  @include opacity;
  @include background;
  @include shadows;
  @include fonts;
  @include transitions;
}

$container-breakpoint-card-width: 55rem; // ~880px card width
$breakpoint-x-small: 512px; // vertical phone
$breakpoint-small: 768px; // vertical ipad
$breakpoint-medium: 1024px; // landscape ipad
$breakpoint-large: 1280px; // small laptop
$breakpoint-x-large: 1440px; // medium laptop
