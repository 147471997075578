%separator {
  margin-left: var(--op-space-small);
  content: '/';
  color: var(--op-color-neutral-on-plus-max-alt);
}

.breadcrumbs {
  display: flex;
  font-size: var(--op-font-small);
  flex-wrap: wrap;
  line-height: var(--op-line-height-base);

  & > a {
    margin-right: var(--op-space-small);
    white-space: nowrap;

    @media (max-width: #{$breakpoint-small}) {
      white-space: normal;
    }

    font-size: var(--op-font-small);
    font-weight: var(--op-font-weight-medium);

    &::after {
      @extend %separator;
    }

    &:last-of-type {
      font-weight: var(--op-font-weight-bold);
      color: var(--op-color-neutral-on-plus-max);

      &::after {
        display: none;
      }
    }
  }

  .separator::after {
    @extend %separator;
    margin-right: var(--op-space-small);
  }
}
