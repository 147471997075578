.video-wrapper {
  display: inline-block;
  margin: 0 auto;
}

.video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */

  div[data-video] {
    visibility: hidden;
  }

  iframe, video {
    width: 560px;
    height: 315px;
  }
}
