.snapshot__navigation {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  margin-top: calc(var(--op-space-2x-large) - var(--op-space-small));
  margin-bottom: var(--op-space-2x-large)
}

.snapshot-title {
  width: 100%;
  margin-top: var(--op-font-small);

  h4 {
    margin: 0;
  }

  h3, h4 {
    white-space: nowrap;

    @media(max-width: #{$breakpoint-x-small}) {
      font-size: calc(var(--op-font-2x-large) - 3px);
      margin: 0;
    }
  }
}

.snapshot-container {
  max-width: 500px;
  margin: 0 auto;
}

.snapshot-container--small {
  max-width: 350px;
  margin: 0 auto;
}

.scale__options {
  display: flex;
}

.scale__question {
  font-size: var(--op-font-medium);
  line-height: var(--op-font-2x-large);
  color: var(--op-color-on-background);
  margin-bottom: calc(var(--op-space-2x-large) + var(--op-space-small));
}

.scale__options label.collection_radio_buttons {
  border-top: var(--op-border-width) solid var(--op-color-primary-minus-six);
  border-left: var(--op-border-width) solid var(--op-color-primary-minus-six);
  border-bottom: var(--op-border-width) solid var(--op-color-primary-minus-six);
  margin: 0;
  text-align: center;
  padding: var(--op-space-large) var(--op-space-small);
}

.scale__options > label:last-of-type label.collection_radio_buttons {
  border-right: var(--op-border-width) solid var(--op-color-primary-minus-six);
  border-radius: 0 var(--op-radius-large) var(--op-radius-large) 0;
}

.scale__options > label:first-of-type label.collection_radio_buttons {
  border-radius: var(--op-radius-large) 0 0 var(--op-radius-large);
}

.scale__options label {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-width: 10%;
}

.scale__options input[type="radio"] {
  display: none;
}

.scale__options input:checked ~ label {
  background-color: var(--op-color-primary-minus-three);
  color: var(--op-color-primary-on-minus-three);
}

.scale__legend {
  margin-top: var(--op-space-small);
  color: var(---op-color-on-background);
}

.alignments {
  display: flex;
  flex-direction: column;
  margin-top: var(--op-space-large);
}

.alignment {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--op-space-x-large);
}

.alignment__title {
  margin-bottom: var(--op-space-medium);
}

.alignment__options {
  width: 18em;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--op-space-small);
}

.alignment__labels {
  width: 18.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: var(--op-space-small);
}

.alignment__option {
  position: relative;
  display: inline-block;
}

// customizations for snapshots#show

.alignment__labels .label {
  font-weight: 500;
  font-size: var(--op-font-small);
}

.alignment__option--display {
  margin-top: 3em;

  height: 2.0em;
  width: 2.0em;
  border-radius: 50%;
  border: 0.15em solid var(--op-color-primary-minus-max);
  margin: 0;
  position: relative;
}

.alignment__option--display.alignment__option--selected:after {
  content: "";
  background-color: var(--op-color-secondary-base);
  position: absolute;
  height: 1.5em;
  width: 1.5em;
  border-radius: 50%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alignment__option-separator {
  margin-top: 1.0em;
  width: 6em;
  border-top: 0.15em solid var(--op-color-primary-minus-max);
}

.alignment__description {
  font-size: var(--op-font-small);
  line-height: var(--op-font-x-large);
  color: var(--op-color-on-background);
}

// overrides for snapshots#alignment

form .alignment__title {
  font-size: var(--op-font-x-large);
  margin-bottom: var(--op-space-small);
}

form .alignment__options {
  flex-direction: column;
  font-size: var(--op-font-medium);
}

form .alignment__option {
  font-size: var(--op-font-small);
  font-weight: 500;
}

.alignment__options .form-control {
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  appearance: none;
  border: none;
  margin-right: var(--op-space-small);
  margin-bottom: 0;
  transform: translate(0, 5px);
}

.alignment__option--input:before {
  content: '';
  height: 1.1em;
  width: 1.1em;
  border-radius: 50%;
  border: 2px solid var(--op-color-primary-minus-three);
  margin: 0;
  position: absolute;
  left: calc(-1.2em - var(--op-space-small));
  // transform: translateY(var(--op-space-x-small));
}

.alignment__options input[type="radio"]:checked + label::after {
  content: "";
  background-color: var(--op-color-primary-minus-three);
  position: absolute;
  height: .6em;
  width: .6em;
  border-radius: 50%;
  left: calc(-1em - var(--op-space-small) + 1px);
  top: 4px;
}

ol.annual-summary {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;

  list-style: none;
  margin: 0;
  padding: 12px;

  cursor: initial;
  user-select: initial;

  @media screen and (max-width: #{$breakpoint-large}){
    grid-template-columns: repeat(6, 1fr);
  }

  &.annual-summary--alignment {
    @media screen and (max-width: #{$breakpoint-small}){
      grid-template-columns: repeat(4, 1fr);
    }
  }

  li {
    --li-size: 60px;

    display: grid;
    gap: 2px;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    line-height: 0;
    padding: 8px;

    height: var(--li-size);
    text-align: center;
  }
}

.annual-summary__score {
  font-weight: 700;
}
