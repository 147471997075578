:root {
  --scroll-margin-top: calc(22  * var(--op-size-unit)); // 88px
  --scroll-margin-top-mobile: calc(34  * var(--op-size-unit)); // 136px
  --c12-expandable-grid-column-width: 30rem;
}

// Base styles for the card and grid row 
.card--expanded {
  align-self: flex-start;
}

.expandable-grid {
  display: grid;
  grid: auto-flow dense / repeat(auto-fit, minmax(var(--c12-expandable-grid-column-width), 1fr));
  gap: var(--op-space-large);
  @media (width < #{$breakpoint-small}) {
    --c12-expandable-grid-column-width: 36rem;
  }
  @media (width < #{$breakpoint-small}) {
    grid: auto-flow / 1fr;
  }
  
  .card-resource {
    grid-row: auto;
    scroll-margin-top: var(--scroll-margin-top);
    @media (width < #{$breakpoint-small}) {
      --scroll-margin-top: var(--scroll-margin-top-mobile);
    }
  }
  
  .card--expanded {
    grid-column: span 2;
    grid-row: span 2;
    @media (width < #{$breakpoint-small}) {
      grid-column: 1;
      grid-row: auto;
    }
  }
}
