.chart__controls {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  @media (max-width: #{$breakpoint-small}) {
    flex-direction: column;
  }
}

.chart__tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--op-space-large);
  gap: var(--op-space-3x-small);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.chart__tab {
  display: inline-flex;
  justify-content: center;
  gap: var(--op-space-2x-small);
  align-items: center;
  padding: 0 var(--op-space-large);
  min-height: var(--op-input-height-medium);
  font-size: var(--op-font-small);
  line-height: var(--op-line-height-densest);
  color: var(--op-color-on-background);
  border-radius: var(--op-radius-pill);
  transition: var(--c12-transition-slow);
  white-space: nowrap;

  @media (width <=#{$breakpoint-x-small}) {
    padding: 0 var(--op-space-medium);
  }

  &:active {
    background-color: var(--op-color-primary-minus-three);
    color: var(--op-color-primary-on-minus-three);
  }
}

.chart__tab:hover {
  background-color: var(--op-color-primary-minus-three);
  color: var(--op-color-primary-on-minus-three);
}

.chart__tab--active {
  background-color: var(--op-color-primary-minus-three);
  color: var(--op-color-primary-on-minus-three);

  &:hover {
    background-color: var(--op-color-primary-minus-four);
    color: var(--op-color-primary-on-minus-four);
  }
}

@media (max-width: #{$breakpoint-medium}) {
  .chart__select {
    margin-bottom: var(--op-space-small);
  }
}

// CSS hack to get a consistent aspect ratio box onscreen
// https://css-tricks.com/aspect-ratio-boxes/#the-pseudo-element-tactic
.chart__figures::before {
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 50%;
}

.chart__figures::after {
  content: "";
  display: table;
  clear: both;
}

.metric__number {
  font-size: var(--op-font-2x-large);
  margin: var(--op-space-small);
  font-weight: 500;
  text-align: center;
}

.metric__number--danger {
  color: var(--op-color-alerts-danger-base); // TODO: No Optics pattern for color without a background-color: ;
}

.chart-donut {
  position: relative;
}

.chart-donut__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.chart-funnel {
  display: grid;
  gap: var(--op-space-medium);
  grid-auto-flow: column;
  justify-content: flex-start;
  overflow-x: auto;

  @media screen and (max-width: #{$breakpoint-medium}) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    overflow: visible;
  }

  @media print {
    gap: var(--op-space-3x-small);
  }
}

.chart-funnel__item {
  @extend %card-global;
  display: grid;
  min-width: calc(30 * var(--op-size-unit)); // 120px
  grid-auto-flow: column;
  line-height: var(--op-line-height-densest);
  align-content: flex-start;
  gap: var(--op-space-medium);
  padding: var(--op-space-medium);

  @media print {
    gap: var(--op-space-2x-small);
    padding: var(--op-space-2x-small);
    min-width: calc(20 * var(--op-size-unit));
  }
}

.chart-funnel__contents {
  display: grid;
  justify-items: flex-start;
  align-content: flex-start;
  gap: var(--op-space-medium);
  grid-template-rows: 1fr auto 1fr;

  @media print {
    gap: var(--op-space-2x-small);
  }

  & > span {
    text-transform: uppercase;
    font-size: var(--op-font-small);
    font-weight: var(--op-font-weight-medium);

    @media print {
      font-size: var(--op-font-2x-small);
      max-width: min-content;
    }
  }

  // Override in this specific instance
  .metric__number {
    margin: 0;
  }
}

.chart-funnel__percent-separator {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .custom-icons {
    color: var(--op-color-secondary-base);
  }

  .percent-number {
    margin: var(--op-space-x-small);
    font-size: var(--op-font-small);
  }
}

.chart__tabs-container {
  overflow-x: scroll;
}

.chart-top-performers {
  display: grid;
  grid: auto auto/auto auto;
  gap: var(--op-space-small);

  @media (max-width: #{$breakpoint-medium}) {
    grid-template-columns: 1fr;
  }
}

.chart-anticipated {
  display: grid;
  gap: var(--op-space-small);
  grid-template-columns: 1fr 1fr;

  @media (max-width: #{$breakpoint-medium}) {
    grid-template-columns: 1fr;
  }
}
