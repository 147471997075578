.password-criteria__list {
  list-style: none;
  padding-left: 0;
}

.password-criteria {
  position: relative;
  padding-left: 28px;

  &::before {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 5px;
    font-family: "Material Symbols Outlined";
    content: "check_circle";
    font-size: var(--op-font-x-large);
    color: var(--op-color-neutral-on-plus-max);
    margin-right: var(--op-space-2x-small);
  }

  span {
    font-style: italic;
    font-size: var(--op-font-small);
    color: var(--op-color-neutral-on-plus-max-alt);
  }
}

.card--themed {
  .password-criteria {
    &::before {
      color: var(--op-color-primary-on-minus-five);
    }
    span {
      font-style: italic;
      font-size: var(--op-font-small);
      color: var(--op-color-primary-on-minus-five);
    }
  }

  .password-criteria--success {
    &::before {
      color: var(--op-color-alerts-notice-base); // TODO: No optics pattern for a color on a different background
    }
  }

  .password-criteria__input {
    &::after {
      color: var(--op-color-neutral-on-plus-max-alt);
    }
  }
}

.password-criteria__input {
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 10px;
    font-family: "Material Symbols Outlined";
    content: "check_circle";
    vertical-align: middle;
    color: var(--op-color-neutral-on-plus-max);
  }
}

.password-criteria__input--success {
  &::after {
    color: var(--op-color-alerts-notice-base); // TODO: No optics pattern for a color on a different background
  }

  .form-control {
    border-color: var(--op-color-alerts-notice-base);
  }
}

.password-criteria--success {
  &::before {
    color: var(--op-color-alerts-notice-base); // TODO: No optics pattern for a color on a different background
  }
}
